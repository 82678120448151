export const Cookies = {
    init: function () {
        let consentCookie = Cookies.getCookie('cookieConsent');
        if (consentCookie !== 'true') {
            let cookieBar = document.querySelector('.cookie-consent-bar');
            if (cookieBar) {
                cookieBar.style.display = 'flex';
            }
        }
    },
    acceptCookieConsent: function () {
        Cookies.setCookie('cookieConsent', 'true', 365, 'Lax');
        let cookieBar = document.querySelector('.cookie-consent-bar');
        if (cookieBar) {
            cookieBar.style.display = 'none';
        }
    },
    getCookie: function (cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    },
    setCookie: function (cname, cvalue, exdays, samesite) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + d.toUTCString();
        let samesiteValue = 'SameSite=' + samesite;
        document.cookie = cname + '=' + cvalue + ';' + expires + ';' + samesiteValue + ';path=/';
    }
}