/*
    Instructions:
    
    - Add identical data target to button and modal div.
    - Add class .js-popup-trigger to button
    - Add class .popup-modal to modal div.
    - Add <i class='popup-modal__close'><span>Close</span></i> inside modal div to add close functionality.

    Example:

    <button class='scroll-count-button js-popup-trigger' data-popup-trigger='mymodalbox'>Show my modal</button>

    <div class='popup-modal' data-popup-modal='mymodalbox'>
        <i class='popup-modal__close'><span>Close</span></i>
        <h2>Räkna ut antal rullar</h2>
    </div>
*/

export const ModalPopup = {
    addToCartModal: null,
    addToCartPopup: null,
    timer: null,
    init: function () {
        if (!document.querySelector('.popup-modal')) {
            return;
        }
        const modalAutocloseTriggers = document.querySelectorAll('.js-popup-trigger-autoclose');
        const modalTriggers = document.querySelectorAll('.js-popup-trigger');
        const modalContainer = document.querySelector('.modal-container');

        const broadcastMessage = document.querySelector('.broadcast-message');
        if (broadcastMessage) {
            const message = broadcastMessage.querySelector('.container').innerHTML;

            broadcastMessage.querySelector('.broadcast-message-closebtn').addEventListener('click', function () {
                broadcastMessage.classList.remove('broadcast-message--visible');
            });

            if (message && message.trim().length > 0) {
                ModalPopup.broadcast(message);
            }
        }

        modalTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                const { popupTrigger } = trigger.dataset;
                const popupModal = document.querySelector(
                    `[data-popup-modal='${popupTrigger}']`
                );

                popupModal.classList.add('popup-modal__is-visible');
                modalContainer.classList.add('modal-container__is-visible');

                const closeButton = popupModal.querySelector('.popup-modal__close');
                if (closeButton) {
                    closeButton.addEventListener('click', () => {
                        popupModal.classList.remove('popup-modal__is-visible');
                        modalContainer.classList.remove('modal-container__is-visible');
                        ModalPopup.stopVideo();

                        if (document.getElementById('js-checklistpage')) {
                            CheckList.resetChecklistSendEmail();
                        }
                    });
                }

                popupModal.querySelectorAll('.add-to-cart__button').forEach(addToCartButton => {
                    if (addToCartButton) {
                        addToCartButton.addEventListener('click', () => {
                            popupModal.classList.remove('popup-modal__is-visible');
                            modalContainer.classList.remove('modal-container__is-visible');
                        });
                    }
                });

                modalContainer.addEventListener('click', () => {
                    popupModal.classList.remove('popup-modal__is-visible');
                    modalContainer.classList.remove('modal-container__is-visible');
                    ModalPopup.stopVideo();

                    // We don't haven't any better way of knowing which modal is being closed,
                    // so we'll reset the login form every time use to be sure.
                    Login.reset();

                    if (document.getElementById('js-checklistpage')) {
                        CheckList.resetChecklistSendEmail();
                    }
                });
            });
        });

        modalAutocloseTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                const { popupTrigger } = trigger.dataset;
                const popupElement = document.querySelector('.popup-autoclose');
                const popupModal = document.querySelector(
                    `[data-popup-modal='${popupTrigger}']`
                );

                popupModal.classList.add('popup-modal__is-visible');
                modalContainer.classList.add('modal-container__is-visible');
                modalContainer.classList.add('modal-container__transparent');

                setTimeout(function () {
                    popupElement.classList.remove('popup-modal__is-visible');
                    modalContainer.classList.remove('modal-container__is-visible');
                    modalContainer.classList.remove('modal-container__transparent');
                }, 1200);
            });
        });

        ModalPopup.addToCartPopup = document.querySelector('.addtocart-modal-popup');
        if (ModalPopup.addToCartPopup) {
            ModalPopup.addToCartPopup.addEventListener('mouseenter', function () { ModalPopup.pauseTimer(); });
            ModalPopup.addToCartPopup.addEventListener('mouseleave', function () { ModalPopup.resumeTimer(); });
            document.addEventListener('click', function (e) {
                ModalPopup.clickOutsideAddToCartModal(e);
            });


        }
    },
    showMessage: function (heading, message) {
        let modal = document.querySelector(".addtocart-modal-popup");
        let markup = `
                <div class="addtocart-modal-popup__inner">
                    <div class="addtocart-modal-popup__information">
                        <div class="addtocart-modal-popup__heading">${heading}</div>
                        <div>${message}</div>
                    </div>
                </div>`;
        modal.innerHTML = markup;

        ModalPopup.showAddToCartPopup(modal);
    },
    showAddToCartPopup: function (modal) {
        modal.classList.add('popup-modal__is-visible');

        ModalPopup.addToCartModal = modal;
        ModalPopup.timer = ModalPopup.startTimer(5, function () { ModalPopup.hide(modal); });
    },
    hide: function (modal) {
        modal.classList.remove('popup-modal__is-visible');
        ModalPopup.timer = null;
    },
    broadcast: function (message) {
        let broadcastMessage = document.querySelector('.broadcast-message');
        broadcastMessage.querySelector('.container').innerHtml = message;
        broadcastMessage.classList.add('broadcast-message--visible');
    },
    stopVideo: function () {
        var filmblocks = document.querySelectorAll('.filmblock');
        for (var i = 0; i < filmblocks.length; i++) {
            var iframe = filmblocks[i].querySelector('iframe');
            var video = filmblocks[i].querySelector('video');
            if (iframe !== null) {
                var iframeSrc = iframe.src;
                iframe.src = iframeSrc;
            }
            if (video !== null) {
                video.pause();
            }
        }
    },
    startTimer: function (seconds, oncomplete) {
        var startTime, timer, obj, ms = seconds * 1000;
        obj = {};
        obj.resume = function () {
            startTime = new Date().getTime();
            timer = setInterval(obj.step, 250); // adjust this number to affect granularity
            // lower numbers are more accurate, but more CPU-expensive
        };
        obj.pause = function () {
            ms = obj.step();
            clearInterval(timer);
        };
        obj.step = function () {
            var now = Math.max(0, ms - (new Date().getTime() - startTime)),
                m = Math.floor(now / 60000), s = Math.floor(now / 1000) % 60;
            s = (s < 10 ? "0" : "") + s;
            if (now == 0) {
                clearInterval(timer);
                obj.resume = function () { };
                if (oncomplete) oncomplete();
            }
            return now;
        };
        obj.resume();
        return obj;
    },
    pauseTimer: function () {
        if (ModalPopup.timer) {
            ModalPopup.timer.pause();
        }
    },
    resumeTimer: function () {
        if (ModalPopup.timer) {
            ModalPopup.timer.resume();
        }
    },
    clickOutsideAddToCartModal: function (e) {
        if (ModalPopup.addToCartModal) {
            if (ModalPopup.addToCartPopup.contains(e.target) == false) {
                ModalPopup.hide(ModalPopup.addToCartModal);
            }
        }
    }
};