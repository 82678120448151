export const OrderHistoryPage = {
    init: function () {
        var togglebtn = document.querySelectorAll('.order__toggle');

        if (togglebtn.length > 0) {
            togglebtn.forEach(function (toggle) {
                toggle.addEventListener('click', OrderHistoryPage.toggle);
            });
        }
    },
    toggle: function (e) {
        var thisTogglebtn = this;
        var orderItems = this.nextElementSibling;
        thisTogglebtn.classList.toggle('is-visible');

        if (!orderItems.classList.contains('is-visible')) {

            orderItems.classList.add('is-visible');
            orderItems.style.height = 'auto';

            var height = orderItems.clientHeight + 'px';

            orderItems.style.height = '0px';

            setTimeout(function () {
                orderItems.style.height = height;
            }, 0);

        }
        else {
            orderItems.style.height = '0px';
            orderItems.classList.remove('is-visible');

            orderItems.addEventListener('transitionend', function () {
                orderItems.classList.remove('is-visible');
            }, {
                once: true
            });
        }
    }
}
