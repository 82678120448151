import 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'jquery-ajax-unobtrusive';
import { Address } from '../src/Address';
import { Accordions } from '../src/Accordions';
import { BuyBlockModal } from '../src/BuyBlockModal';
import { Cart } from '../src/Cart';
import { Questionnaire } from '../src/Questionnaire';
import { CartPage } from '../src/CartPage';
import { CategoryEdit } from '../src/CategoryEdit';
import { Cookies } from '../src/Cookies';
import { Flowbox } from '../src/Flowbox';
import { LoadMorePages } from '../src/LoadMorePages';
import { LoadMoreProducts } from '../src/LoadMoreProducts';
import { MainMenu } from '../src/MainMenu';
import { Minicart } from '../src/Minicart';
import { MinicartFlyin } from './MinicartFlyin';
import { MobileMenu } from '../src/MobileMenu';
import { Tabs } from '../src/Tabs';
import { ModalPopup } from '../src/Modal';
import { Login } from '../src/Login';
import { PackageCalculator } from '../src/PackageCalculator';
import { Product } from '../src/Product';
import { ProductCombination } from '../src/ProductCombination';
import { ProductListingSlideshow } from '../src/ProductListingSlideshow';
import { RollsCalculator } from '../src/RollsCalculator';
import { Search } from '../src/Search';
import { Searchfilter } from '../src/Searchfilter';
import { SearchFlyOut } from '../src/SearchFlyOut';
import { StorePicker } from '../src/StorePicker';
import { Wishlist } from '../src/Wishlist';
import { LinkDropdown } from '../src/LinkDropdown';
import { CustomColorFlyin } from '../src/CustomColorFlyin';
import { OrderHistoryPage } from '../src/OrderHistoryPage';
import { CheckList } from '../src/CheckList';
import { Forms } from '../src/Forms';
import { Navigation } from '../src/Navigation';
import { ConfirmStorePopup } from '../src/ConfirmStorePopup';
import { GTMEvents } from '../src/GTMEvents';
import { tns } from '../src/tiny-slider';
import { WallpaperCalculator } from '../src/WallpaperCalculator';
import '../src/Vendors/slide-menu.js';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/brands'
import { WishlistFlyin } from './WishlistFlyin';

// All CanCan-only scripts go in app.js, not here.
export const initFunction = () => {
    Accordions.init();
    Address.init();
    BuyBlockModal.init();
    Questionnaire.init();
    Cart.init();
    CartPage.init();
    CategoryEdit.init();
    Cookies.init();
    Flowbox.init();
    LoadMorePages.init();
    LoadMoreProducts.init();
    MainMenu.init();
    MinicartFlyin.init();
    Minicart.init();
    MobileMenu.init();
    Tabs.init();
    ModalPopup.init();
    Login.init();
    PackageCalculator.init();
    Product.init();
    ProductCombination.init();
    ProductListingSlideshow.init();
    RollsCalculator.init();
    Search.init();
    Searchfilter.init();
    SearchFlyOut.init();
    StorePicker.init();

    Wishlist.init();
    WishlistFlyin.init();
    LinkDropdown.init();

    CustomColorFlyin.init();
    addStoreToUrl();
    OrderHistoryPage.init();
    showLoginRegisterModal();
    CheckList.init();
    Forms.init();
    Navigation.init();
    ConfirmStorePopup.init();
    GTMEvents.init();

    window.WishlistFlyin = WishlistFlyin;
    window.MainMenu = MainMenu;
    window.LoadMoreProducts = LoadMoreProducts;
    window.Wishlist = Wishlist;
    window.CartPage = CartPage;
    window.Cart = Cart;
    window.StorePicker = StorePicker;
    window.Questionnaire = Questionnaire;
    window.Login = Login;
    window.Cookies = Cookies;
    window.Searchfilter = Searchfilter;
    window.Tabs = Tabs;
    window.tns = tns;
    window.PackageCalculator = PackageCalculator;
    window.WallpaperCalculator = WallpaperCalculator;
    window.ConfirmStorePopup = ConfirmStorePopup;
    window.MinicartFlyin = MinicartFlyin;
    window.MobileMenu = MobileMenu;
    window.SearchFlyOut = SearchFlyOut;
};

const ready = function (callback) {
    if (document.readyState !== "loading") {
        callback();
    }
    else {
        document.addEventListener("DOMContentLoaded", callback);
    }
};

ready(initFunction);

// fix for minicart not updating on browser back on iOS.
// https://stackoverflow.com/questions/10106457/pageshow-is-not-received-when-pressing-back-button-on-safari-on-ipad
var timer;
function onPageBack() { Minicart.updateMiniCart(); }
window.addEventListener('pageshow', function () {
    if (event.persisted) {
        onPageBack();
    }
    // avoid calling onPageBack twice if 'pageshow' event has been fired...
    if (timer)
        clearInterval(timer);
});

// when page is hidden, start timer that will fire when going back to the page...
window.addEventListener('pagehide', function () {
    timer = setInterval(function () {
        clearInterval(timer);
        onPageBack();
    }, 100);
});

function addStoreToUrl() {

    let selectedStore = Cookies.getCookie('selectedstore');
    // if no store in cookie, or on subpage to butiker, return
    if (selectedStore === '' ||
        window.location.pathname.startsWith("/butiker")) {
        return;
    }

    if (history.replaceState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;

        // on startpage add butiker/storename
        if (window.location.pathname === "/") {
            newurl += 'butiker/' + selectedStore + '/';
        }
        // page already has butik query, dont add it again
        else if (window.location.href.includes("butik=" + selectedStore)) {
            return;
        }

        else if (window.location.href.includes('?')) {
            newurl = window.location.href + '&butik=' + selectedStore;
        }
        else {
            newurl += '?butik=' + selectedStore;
        }
        window.history.replaceState({ path: newurl }, '', newurl);
    }
};

function addProductFallbackImage(element, width) {
    var img = document.createElement('img');
    img.classList.add('thumbnail');
    img.src = '/Resources/Graphics/Placeholders/produktbild.jpg?width=' + width;
    element.parentNode.parentNode.appendChild(img);
    element.parentNode.remove()
}
// go back on variants on productdetailspage
window.onpopstate = function (event) {
    let url = window.location.toString();
    let isProductDetailPage = document.querySelector('.productdetailspage');
    if (!isProductDetailPage || !url.indexOf('_')) {
        return;
    }
    let variantId = '';

    if (url.indexOf('?') < 0) {
        variantId = url.substring(url.lastIndexOf("_") + 1);
    }
    else {
        variantId = url.substring(
            url.lastIndexOf("_") + 1,
            url.lastIndexOf("?")
        );
    }

    Product.changeSelectedVariant(variantId, true);
};

function showLoginRegisterModal() {
    var signupButton = document.getElementsByClassName('navigation-quicklink js-popup-trigger')[0];
    var signupTab = document.getElementById('js-signup-tab');
    var loginTab = document.getElementById('js-login-tab');

    if (window.location.href.includes("?loggain") || window.location.href.includes("&loggain")) {
        if (signupButton) {
            signupButton.click();
        }
        if (loginTab) {
            loginTab.click();
        }
    }

    else if (window.location.href.includes("?registrera") || window.location.href.includes("&registrera")) {
        if (signupButton) {
            signupButton.click();
        }
        if (signupTab) {
            signupTab.click();
        }
    }
}