export const CategoryEdit = {
    init: function () {},

    showParametricValuesDropdown: function(e) {
        var dropdowns = document.getElementsByClassName("parametrics-value-dropdown");
        Array.from(dropdowns).forEach(dropdown => {
            dropdown.style.display = "none";
        });

        var selected = document.getElementById("parametrics-dropdown").value;
        var parametricValuesDropdown = document.getElementById("js-parametrics-" + selected.toLowerCase());

        parametricValuesDropdown.style.display = "block";
    },

    updateParametric: function() {
        var dropdown = document.getElementById("parametrics-dropdown");
        var selectedKey = dropdown.value;
        var selectedKeyTranslated = dropdown.options[dropdown.selectedIndex].text;

        var selectedValue = '';
        var dropdowns = document.getElementsByClassName("parametrics-value-dropdown");
        Array.from(dropdowns).forEach(dropdown => {
            if (dropdown.style.display === "block") {
                selectedValue = dropdown.value;
            }
        });

        // update table
        var table = document.getElementById("parametrics-table");
        var row = table.insertRow(1);
        var cell1 = row.insertCell(0);
        var cell2 = row.insertCell(1);
        var cell3 = row.insertCell(2);

        cell1.innerHTML = selectedKeyTranslated;
        cell2.innerHTML = selectedValue;
        cell3.innerHTML = '<button type="submit" onclick="CategoryEdit.deleteParametric(this, \'' + selectedKey + '\',\'' + selectedValue + '\')">Ta bort</button>';

        // update Epi
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                location.reload();

            }
        };
        xhttp.open("POST", "AddParametric", true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send("name=" + selectedKey + "&value=" + selectedValue);
    },

    deleteParametric: function(element, key, value) {
        // remove from table
        element
            .parentNode
            .parentNode
            .parentNode
            .removeChild(element.parentNode.parentNode);

        // update Epi
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                location.reload();
            }
        };
        xhttp.open("POST", "DeleteParametric", true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send("name=" + key + "&value=" + value);
    },
}