export const Searchfilter = {
    init: function () {
        if (!document.querySelector('.searchresult')) {
            return;
        }
        var SearchFilterFlyin = {
            SearchFilterFlyinToggle: document.querySelector('.js-toggle-searchfilter-flyin'),
            SearchFilterFlyinClose: document.querySelector('.js-close-searchfilter-flyin'),
            SearchFilterFlyinContainer: document.querySelector('.searchfilter-flyin'),
            SearchFilterOverlay: document.querySelector('.filter-overlay'),

            toggleFilterFlyIn: function (e) {
                if (e) {
                    e.preventDefault();
                }
                if (this.SearchFilterFlyinContainer) {
                    this.SearchFilterFlyinContainer.classList.toggle('searchfilter-expanded');
                }
                if (SearchFilterFlyin.SearchFilterOverlay !== null) {
                    if (SearchFilterFlyin.SearchFilterOverlay.style.display == "block") {
                        SearchFilterFlyin.SearchFilterOverlay.style.display = "none";
                    }
                    else {
                        SearchFilterFlyin.SearchFilterOverlay.style.display = "block";
                    }
                }
            },
            closeFilterFlyIn: function (e) {
                if (SearchFilterFlyin.SearchFilterFlyinContainer !== null) {
                    if (!SearchFilterFlyin.SearchFilterFlyinContainer.classList.contains('searchfilter-expanded')) return;

                    var isClickInsideSearchfilter = document.querySelector('.searchfilter-expanded').contains(e.target);
                    var isOpenButton = SearchFilterFlyin.SearchFilterFlyinToggle.contains(e.target);

                    if (!isClickInsideSearchfilter && !isOpenButton) {
                        this.SearchFilterFlyinContainer.classList.remove('searchfilter-expanded');
                        if (SearchFilterFlyin.SearchFilterOverlay !== null) {
                            SearchFilterFlyin.SearchFilterOverlay.style.display = "none";
                        }
                    }
                }
            }
        };

        if (SearchFilterFlyin.SearchFilterFlyinToggle) {
            SearchFilterFlyin.SearchFilterFlyinToggle.addEventListener('click', function (e) { SearchFilterFlyin.toggleFilterFlyIn(e); });
        }

        if (SearchFilterFlyin.SearchFilterFlyinClose) {
            SearchFilterFlyin.SearchFilterFlyinClose.addEventListener('click', function (e) { SearchFilterFlyin.toggleFilterFlyIn(e); });
        }

        document.addEventListener('click', function (e) { SearchFilterFlyin.closeFilterFlyIn(e); });

        const menuElement = document.getElementById('filter-menu');
        if (menuElement) {
            var menu = new SlideMenu(menuElement);
        }

        const backLink = document.querySelector('.slide-menu__backlink');
        if (backLink) {
            backLink.innerHTML = "<strong>Tillbaka</strong>";
        }

        const uncheckBtn = document.getElementById('clearFilterButton');
        if (uncheckBtn) {
            if (!uncheckBtn.classList.contains("disabled")) {
                uncheckBtn.addEventListener('click', clearAllAndSubmit);
            }
        }

        if (sessionStorage.showfiltermenu === "true") {
            sessionStorage.removeItem("showfiltermenu");
            SearchFilterFlyin.toggleFilterFlyIn();
        }
    },
    UpdateLink: function (param) {
        var filterButton = document.getElementById('filterButton');
        var currentHref = filterButton.href;

        if (filterButton.href.includes('?') === false) {
            currentHref = currentHref + "?f=";
        }
        if (currentHref.includes(param)) {
            filterButton.href = currentHref.replace(param, '');
        }
        else {
            filterButton.href = currentHref + param;
        }
        const uncheckBtn = document.getElementById('clearFilterButton');
        if (uncheckBtn) {
            if (filterButton.href.includes("f=")) {
                uncheckBtn.classList.remove("disabled")
                uncheckBtn.addEventListener('click', clearAllAndSubmit);
            }
            else {
                uncheckBtn.classList.add("disabled")
                uncheckBtn.removeEventListener('click', clearAllAndSubmit);
            }
        }
    }
};

function clearAllAndSubmit() {
    document.querySelectorAll('.checkbox input:checked').forEach(function (checkbox) {
        checkbox.checked = false;
        checkbox.onchange();
    });

    const filterButton = document.getElementById('filterButton');
    filterButton.click();

    sessionStorage.setItem("showfiltermenu", "true");
};