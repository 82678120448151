import { MobileMenu } from '../src/MobileMenu';

export const SearchFlyOut = {
    searchInput: null,
    searchFlyOut: null,
    productsContainer: null,
    productListContainer: null,
    noProductsFoundContainer: null,
    pageContainer: null,
    pageListContainer: null,
    loader: null,
    modalContainer: null,
    main: null,
    showPrices: null,
    navbar: null,
    nabBar: null,
    init: function () {
        this.navBar = document.querySelector('header#navbar');
        this.searchInput = document.querySelector('#site-search');
        this.searchFlyOut = document.querySelector('.search-flyout');
        this.productsContainer = document.getElementsByClassName('search-flyout__products')[0];
        this.productListContainer = document.getElementsByClassName('flyout-productresultlist')[0];
        this.noProductsFoundContainer = document.getElementsByClassName('flyout-noproducts')[0];
        this.pageContainer = document.getElementsByClassName('search-flyout__pagescontainer')[0];
        this.pageListContainer = document.getElementsByClassName('search-flyout__pagescontainer__pages__resultlist')[0];
        this.loader = document.getElementsByClassName('search-flyout__loader')[0];
        this.modalContainer = document.getElementsByClassName('modal-container-searchflyout')[0];
        this.main = document.getElementsByClassName('main')[0];
        this.navbar = document.getElementById('navbar');        
    },
    searchClick: function () {
        MobileMenu.closeMobileNav();
    },
    searchKeyUp: function () {     
        let delayTimer;
        if (!this.searchFlyOut) {
            return;
        }

        if (this.searchInput.value.length > 2) {
            this.searchFlyOut.style.display = this.searchInput.value.length > 2 ? "block" : "none";
            this.main.style.zIndex = 0;
            this.main.style.position = 'relative';

            if (window.innerWidth <= 576) {
                document.querySelector('body').style.overflow = "hidden";
            }

            this.modalContainer.classList.add('modal-container');
            this.modalContainer.classList.add('modal-container__is-visible');
            this.modalContainer.style.position = 'absolute';

            this.modalContainer.addEventListener('click', () => {
                SearchFlyOut.hideFlyout();
            });

            SearchFlyOut.setFlyoutSearchHeight();

            this.navbar.addEventListener('click', () => {
                SearchFlyOut.hideFlyout();
            });

            clearTimeout(delayTimer);
            SearchFlyOut.showLoader();

            const deBounce = (func) => {
                let timer;
                return (event) => {
                    if (timer) clearTimeout(timer);
                    timer = setTimeout(func, 100, event);
                };
            };

            window.addEventListener('resize', deBounce(function (e) {
                SearchFlyOut.setFlyoutSearchHeight();
            }));

            delayTimer = setTimeout(function () {
                SearchFlyOut.updateSearchResult();
            },
                500);
        }
    },
    resetSearch: function () {
        this.noProductsFoundContainer.style.display = 'none';
        this.productListContainer.innerHTML = '';

        this.pageListContainer.innerHTML = '';
        this.pageContainer.style.display = 'none';
    },
    showLoader: function () {
        this.loader.style.display = 'block';
        this.productsContainer.style.display = 'none';
        this.pageContainer.style.display = 'none';
    },
    hideLoader: function () {
        this.loader.style.display = 'none';
        this.productsContainer.style.display = 'block';
    },
    updateSearchResult: function () {
        var params = this.searchInput.value;
        var xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {

                var responeSearchResults = JSON.parse(this.responseText);
                SearchFlyOut.resetSearch();

                if (!responeSearchResults) {
                    return;
                }

                SearchFlyOut.showPrices = responeSearchResults.ShowPrices;

                if (responeSearchResults.Products.length === 0) {
                    this.noProductsFoundContainer.style.display = 'flex';
                }
                else {
                    SearchFlyOut.addProducts(responeSearchResults.Products, responeSearchResults.TrackId, responeSearchResults.Query);
                }

                if (responeSearchResults.Pages.length > 0) {
                    document.getElementsByClassName('search-flyout__pagescontainer')[0].style.display = 'block';
                    SearchFlyOut.addPages(responeSearchResults.Pages, responeSearchResults.TrackId, responeSearchResults.Query);
                }

                setTimeout(function () {
                    SearchFlyOut.hideLoader();
                },
                    500);
            }
        }
        if (params && params.length > 2) {
            xmlhttp.open('GET', '/SearchResultPage/GetFlyoutSearchResults?q=' + params, true);
            xmlhttp.send(params);
        }
    },
    addProducts: function (products, trackId, query) {
        products.forEach(element => {
            // flyout product
            let product = document.createElement('div');
            product.className = 'flyout-product flyout__productitem';

            let priceDiv = '';
            if (SearchFlyOut.showPrices === true) {
                priceDiv = `<div class="flyout__itemprice">${element.Price}</div>`;
                if (element.DiscountPrice !== null && element.DiscountPrice !== element.Price) {
                    //<span class="product-list-item__price--recommended flyout__itemprice">${element.Price}</span>
                    //<span class="product-list-item__price--discounted flyout__itemprice">${element.DiscountPrice}</span>
                    priceDiv = `<div class="product-listing__collection product-list-item__price">
                                <span class="product-list-item__price flyout__itemprice">${element.DiscountPrice}</span>
                            </div>`
                }
            }

            let imageFallbackSrc = '/Resources/Graphics/Placeholders/produktbild.jpg';
            let imageResizeParams = '?width=48&height=48&amp;mode=crop&amp;quality=80';

            let markupProduct = `                            
                <a href="${element.ProductsDetailsUrl}" onclick="Search.track('${element.HitId}','${trackId}','${query}');">
                        <img src="https://colorama.cdn.storm.io/${element.ImageKey}${imageResizeParams}" 
                             onerror="this.src='${imageFallbackSrc}${imageResizeParams}'">
                        <div class="flyout__productitem__container">
                            <div class="flyout__productname">${element.Name}, ${element.Brand}</div>                            
                            ${priceDiv}
                        </div>
                </a>`;
            product.innerHTML = markupProduct;
            this.productListContainer.appendChild(product);
        });
    },
    addPages: function (pages, trackId, query) {
        pages.forEach(pageElement => {
            let page = document.createElement('div');
            page.className = 'flyout-page flyout__pageitem';

            let imageSrc = pageElement.Image;
            let imageFallbackSrc = '/Resources/Graphics/Placeholders/artikelbild.jpg';
            let imageResizeParams = '?width=53&amp;height=30&amp;mode=crop&amp;quality=80';

            if (imageSrc === null) {
                imageSrc = imageFallbackSrc;
            }

            let markupPage = `                       
                    <div class="flyout__pageitem__container">
                        <img src="${imageSrc}${imageResizeParams}" 
                             onerror="this.src='${imageFallbackSrc}${imageResizeParams}'">
                        <a class="flyout__pagelink"
                           href="${pageElement.Url}"
                           onclick="Search.track('${pageElement.HitId}','${trackId}','${query}');">
                            ${pageElement.Name}
                        </a>
                    </div>`;

            page.innerHTML = markupPage;
            this.pageListContainer.appendChild(page);
        });
    },
    hideFlyout: function () {
        this.main.style.zIndex = 0;
        this.main.style.position = 'initial';

        if (window.innerWidth <= 576) {
            document.querySelector('body').style.overflow = "unset";
        }

        this.modalContainer.classList.remove('modal-container');
        this.modalContainer.classList.remove('modal-container__is-visible');
        this.modalContainer.style.position = 'fixed';
        this.searchFlyOut.style.display = 'none';
    },
    setFlyoutSearchHeight: function () {
        let winH = window.innerHeight;
        let navH = this.navBar.offsetHeight + 6;
        let finalH = Math.floor(winH - navH);

        this.searchFlyOut.style.height = finalH + 'px';

        if (window.innerWidth <= 576) {
            this.searchFlyOut.style.height = finalH + 'px';
        }
        else {
            this.searchFlyOut.style.height = 'auto';
        }

    }
}
