export const ProductCombination = {

    init: function () {
        const selectProduct = document.querySelector('.js-product-combination-select');
        const selectColor = document.querySelector('.js-color-combination-select');

        if (selectColor) {
            selectColor.onclick = function (e) {
                toggle('color');
            };
        }

        if (selectProduct) {
            selectProduct.onclick = function (e) {
                toggle('wallpaper');
            };
        }

        function active(node) {
            if(!node) return;
            node.classList.add('btn-green');
            node.classList.remove('btn-green-outline');
        }

        function passive(node) {
            if (!node) return;
            node.classList.add('btn-green-outline');
            node.classList.remove('btn-green');
        }

        function toggle(type) {

            if (type === 'color') {
                active(selectColor);
                passive(selectProduct);
            } else {
                active(selectProduct);
                passive(selectColor);
            }

            const view = document.querySelector('.js-product-combination-view');
            const items = view.querySelectorAll("[data-type]");
            for (var i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.dataset.type === type) {
                    item.classList.remove('d-none');
                } else {
                    item.classList.add('d-none');
                }
            }
        }
    }
};