import { Product } from '../src/Product';

function autocomplete(inp, arr, onValueChanged) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener('input', function (e) {
        var a, b, i, val = this.value;
        /*close any already open lists of autocompleted values*/
        closeAllLists();

        if (!val) {
            /* clear selected color */
            CustomColorFlyin.nscColorInputOnChange('');
            return false;
        }

        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement('DIV');
        a.setAttribute('id', this.id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);

        const inputValue = val.replace(/[/ -]/g, '').toUpperCase();
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            /*ignore spaces and dashes when comparing values.*/
            const colorValue = arr[i].Name.replace(/[/ -]/g, '').toUpperCase();

            /*check if the item contains with the same letters as the text field value:*/
            if (colorValue.includes(inputValue)) {

                if (colorValue.length === inputValue.length) {
                    onValueChanged(val);
                }

                /*create a DIV element for each matching element:*/
                b = document.createElement('DIV');
                b.className = 'ncs-color-suggestion';
                b.innerHTML += arr[i].Name;

                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += '<input type="hidden" value="' + arr[i].Name + '">';
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener('click', function (e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName('input')[0].value;

                    if (onValueChanged) {
                        onValueChanged(inp.value);
                    }

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener('keydown', function (e) {
        var x = document.getElementById(this.id + 'autocomplete-list');
        if (x) x = x.getElementsByTagName('div');
        if (e.keyCode === 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add('autocomplete-active');
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    /*execute a function when someone clicks in the document:*/
    document.addEventListener('click', function (e) {
        closeAllLists(e.target);
    });
}

export const CustomColorFlyin = {
    init: function () {
        if (!document.querySelector('.js-toggle-customcolorflyin')) {
            return;
        }

        CustomColorFlyin.colorFlyinToggle = document.querySelector('.js-toggle-customcolorflyin');
        CustomColorFlyin.colorFlyinClose = document.querySelector('.js-close-colorflyin');
        CustomColorFlyin.colorFlyinContainer = document.querySelector('.customcolor-flyin');

        if (CustomColorFlyin.colorFlyinToggle) {
            CustomColorFlyin.colorFlyinToggle.addEventListener('click', function (e) {
                CustomColorFlyin.variantId = this.dataset.variantId;
                CustomColorFlyin.toggleColorFlyIn(e);
            });
        }

        if (CustomColorFlyin.colorFlyinClose) {
            CustomColorFlyin.colorFlyinClose.addEventListener('click', CustomColorFlyin.toggleColorFlyIn);
        }

        document.addEventListener('click', CustomColorFlyin.closeColorFlyIn);

        if (colors) {
            let colorGroupsElements = document.querySelectorAll('.color-group');
            colorGroupsElements.forEach(function (clickedGroup) {
                clickedGroup.addEventListener("click", function () {
                    const color = colors.find(x => x.ColorId === clickedGroup.dataset.colorId);
                    CustomColorFlyin.selectColor(color);
                });
            });

            CustomColorFlyin.ncscolorpreview = document.getElementById('ncscolorpreview');
            CustomColorFlyin.selectColorButton = document.querySelector('.btn-select-color');
            CustomColorFlyin.selectColorButton.addEventListener('click', CustomColorFlyin.setColorValue);
            CustomColorFlyin.nscColorInput = document.querySelector('.ncs-color-input');

            autocomplete(CustomColorFlyin.nscColorInput, colors[colors.length - 1].Tints, CustomColorFlyin.nscColorInputOnChange);

            // if one color group, select and hide it.
            if (colorGroupsElements.length === 1) {
                let color = colors.find(x => x.ColorId === colorGroupsElements[0].dataset.colorId);
                CustomColorFlyin.selectColor(color);

                // hide colorgroup element
                let colorTypeChoiceElement = document.getElementsByClassName("color-type-choice")[0];
                if (colorTypeChoiceElement) {
                    colorTypeChoiceElement.style.display = "none";
                }

                // rename choose color
                var colorChoiceTitleElement = document.querySelector('.color-choice .h4');
                if (colorChoiceTitleElement) {
                    colorChoiceTitleElement.textContent = colorChoiceTitleElement.textContent.replace("2. ", "");
                }
            }
        }
    },
    colorFlyinToggle: null,
    colorFlyinClose: null,
    colorFlyinContainer: null,
    variantId: null,
    nscColorInput: null,
    selectColorButton: null,
    ncscolorpreview: null,
    selectedThumbnailColorTemp: null,
    nscColorInputOnChange: function (nscCode) {
        const color = colors[colors.length - 1].Tints.find(x => x.Name.toUpperCase() === nscCode.toUpperCase());

        if (color) {
            CustomColorFlyin.selectColorValue(color);
            ncscolorpreview.style.stroke = 'None';
        }
        else {
            CustomColorFlyin.selectColorButton.disabled = true;
            let selectedcolor = document.getElementById('selectedcolor');
            let ncscolorpreview = document.getElementById('ncscolorpreview');
            selectedcolor.innerText = '';
            ncscolorpreview.style.fill = 'None';
            ncscolorpreview.style.stroke = '#575656';
        }
    },
    toggleColorFlyIn: function (e) {
        e.preventDefault();
        if (CustomColorFlyin.colorFlyinContainer) {
            CustomColorFlyin.colorFlyinContainer.classList.toggle('colorpicker-expanded');
        }
    },
    closeColorFlyIn: function (e) {

        if (e.target.className === 'ncs-color-suggestion') {
            return;
        }

        if (!CustomColorFlyin.colorFlyinContainer.classList.contains('colorpicker-expanded')) {
            return;
        }

        let isClickInsideColorFlyin = document.querySelector('.colorpicker-expanded').contains(e.target);
        let isOpenButton = document.querySelector('.js-toggle-customcolorflyin').contains(e.target);

        if (!isClickInsideColorFlyin && !isOpenButton) {
            CustomColorFlyin.colorFlyinContainer.classList.remove('colorpicker-expanded');
        }
    },
    closeFlyin: function () {
        document.querySelector('.js-close-colorflyin').click();
    },
    selectColor: function (selectedColor) {
        let colorContainer = document.querySelector('.group-colors');
        let ncsContainer = document.querySelector('.group-colors-ncs');

        if (selectedColor.ColorId.toLowerCase() === 'ncs') {
            ncsContainer.style.display = 'flex';
            colorContainer.style.display = 'none';
            CustomColorFlyin.ncscolorpreview.style.fill = 'None';
            CustomColorFlyin.nscColorInput.value = '';
            CustomColorFlyin.selectColorButton.disabled = true;
        }
        else {
            ncsContainer.style.display = 'none';
            colorContainer.style.display = 'flex';

            colorContainer.textContent = '';

            if (selectedColor.Tints) {
                selectedColor.Tints.forEach(function (color) {
                    let colorDiv = document.createElement('div');
                    colorDiv.className = 'color';

                    let colorCircle = document.createElement('span');
                    colorCircle.style = `background-color:#${color.HexCode}`;
                    colorDiv.appendChild(colorCircle);
                    let textNode = document.createTextNode(color.Name);
                    colorDiv.appendChild(textNode);
                    colorContainer.appendChild(colorDiv);
                    colorDiv.addEventListener('click', () => CustomColorFlyin.selectColorValue(color));
                });
            }
        }
    },
    selectColorValue: function (color) {
        let selectedcolor = document.getElementById('selectedcolor');
        selectedcolor.innerText = color.UniqueName;
        CustomColorFlyin.ncscolorpreview.style.fill = color.HexCode;
        CustomColorFlyin.selectColorButton.disabled = false;
        CustomColorFlyin.selectedThumbnailColorTemp = color.HexCode;
    },
    setColorValue: function () {
        let selectedcolor = document.getElementById('selectedcolor');
        let selectedColorInput = document.getElementById('colorinfo');
        selectedColorInput.value = selectedcolor.innerText;

        const packageSizeDropdown = document.getElementById('product-package-size');
        if (packageSizeDropdown && packageSizeDropdown.options.length > 0) {
            Array.from(packageSizeDropdown.options).forEach(function (option) {
                if (option.dataset.colorname === 'valfri') {
                    option.hidden = false;
                    option.disabled = false;
                } else {
                    option.remove();
                }
            });
            packageSizeDropdown.disabled = false;
        }

        const selectedColorLabel = document.getElementById('selectedColorLabel');
        if (selectedColorLabel) {
            selectedColorLabel.innerText = 'valfri';
        }

        const customColorLabel = document.getElementById('CustomColorLabel');
        if (customColorLabel) {
            customColorLabel.value = selectedcolor.innerText;
        }

        // set selectedColor thumbnail
        let selectedThumnailEl = document.getElementById("jsSelectedThumnail");
        if (selectedThumnailEl) {
            selectedThumnailEl.style.display = "block";
            const hash = CustomColorFlyin.selectedThumbnailColorTemp.startsWith("rgb") ? "" : "#";
            selectedThumnailEl.style.backgroundColor = hash + CustomColorFlyin.selectedThumbnailColorTemp;
        }

        CustomColorFlyin.closeFlyin();
        const currentColorName = document.getElementById('CustomColorCurrent').value;
        if (selectedColorInput.value != currentColorName) {
            // If the currently displayed variant is not the custom color, reload the product page.
            Product.changeSelectedVariant(CustomColorFlyin.variantId, null, false);
        }
    },
    reset: function () {
        document.getElementById('colorinfo').value = null;
        document.getElementById('selectedcolor').innerText = '';
    }
};