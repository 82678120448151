import { Search } from '../src/Search';

export const GTMEvents = {
    init: function () {
        let pushedECommerce = GTMEvents.pushProductImpressionAndOrDetails();

        if (pushedECommerce === false) {
            GTMEvents.trackPageType();
        }
    },
    displayConsoleLog: false,
    listedProducts: 0,

    pusheEcommerceObject(object, callback) {
        let selectedStore = document.querySelector('.js-storename').value;
        if (selectedStore) {
            object["selectedStore"] = selectedStore;
        }

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

        if (callback) {
            dataLayer.push(object, callback);
        }
        else {
            dataLayer.push(object);
        }

        if (GTMEvents.displayConsoleLog) {
            console.log('gtm', object);
        }
    },
    pushProductImpressionAndOrDetails: function () {
        var gtm = {
            ecommerce: {
                /*'currencyCode': 'SEK'*/
            }
        };

        // add impressions
        var products = Array.apply(null, document.querySelectorAll('.js-product-link-click')).slice(GTMEvents.listedProducts);
        if (products.length > 0) {

            products.forEach(product => {
                product.addEventListener('click', function (e) { GTMEvents.handleLinkClick(e, product); });
            });

            gtm['ecommerce']['impressions'] = products.map(function (product) {
                return {
                    'name': product.dataset.productName,
                    'id': product.dataset.productCode,
                    'price': product.dataset.productPrice,
                    'brand': product.dataset.productBrand,
                    'variant': product.dataset.productVariant,
                    'list': product.dataset.productList,
                    'position': product.dataset.productPosition,
                    'dimension2': GTMEvents.getDimension2(product.dataset.productCode)
                };
            })

            GTMEvents.listedProducts += products.length;
        }

        // add details
        const productElement = document.querySelector('.js-product-addtocart');
        if (productElement) {
            gtm['ecommerce']['detail'] = {
                'actionField': { 'list': 'product detail page' },
                'products': [{
                    'name': productElement.dataset.productName,
                    'id': productElement.dataset.productCode,
                    'price': productElement.dataset.productPrice,
                    'brand': productElement.dataset.productBrand,
                    'category': productElement.dataset.productCategory,
                    'variant': productElement.dataset.productVariant,
                    'dimension2': GTMEvents.getDimension2(productElement.dataset.productCode)
                }]
            }
        }

        // only push if anything inside ecommerce
        if (products.length > 1 || productElement) {
            GTMEvents.pusheEcommerceObject(gtm);
            return true;
        }

        return false;
    },
    // cart
    removeFromCartGTM: function (productCode, productName, productPrice, productBrand, productCategory, productVariant, newQuantity) {
        const gtm = {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [{
                        name: productName,
                        id: productCode,
                        price: productPrice,
                        brand: productBrand,
                        category: productCategory,
                        variant: productVariant,
                        quantity: newQuantity,                        
                        dimension2: this.getDimension2(productCode)
                    }]
                }
            }
        };

        GTMEvents.pusheEcommerceObject(gtm);
    },

    // product actions
    addToCartGTM: function (quantity) {
        const productElement = document.querySelector('.js-product-addtocart');
        if (!productElement) return;

        const gtm = {
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: [{
                        name: productElement.dataset.productName,
                        id: productElement.dataset.productCode,
                        price: productElement.dataset.productPrice,
                        brand: productElement.dataset.productBrand,
                        category: productElement.dataset.productCategory,
                        variant: productElement.dataset.productVariant,
                        quantity,                        
                        dimension2: this.getDimension2(productElement.dataset.productCode)
                    }]
                }
            }
        };

        GTMEvents.pusheEcommerceObject(gtm);
    },
    handleLinkClick: function (e, productDiv) {
        if (!productDiv) {
            return;
        }

        if (e) {
            e.preventDefault();
        }
        const url = productDiv.dataset.url;
        let list = productDiv.dataset.productList;
        const pageTypeElement = document.querySelector('.js-pagetype');

        if (pageTypeElement !== null && pageTypeElement.value !== null) {
            if (pageTypeElement.value === 'SearchResultPage') {
                list = 'Search Results';
            }

            if (pageTypeElement.value === 'CategoryPage') {
                list = 'Category Listing';
            }
        }

        let gtm = {
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'actionField': { 'list': list },
                    'products': [{
                        'name': productDiv.dataset.productName,
                        'id': productDiv.dataset.productCode,
                        'price': productDiv.dataset.productPrice,
                        'brand': productDiv.dataset.productBrand,
                        'category': productDiv.dataset.productCategory,
                        'variant': productDiv.dataset.productVariant,
                        'position': productDiv.dataset.productPosition,                        
                        'dimension2': this.getDimension2(productDiv.dataset.productCode)
                    }]
                }
            }
        };

        GTMEvents.pusheEcommerceObject(gtm, function () {
            if (url) {
                document.location = url;
            }
        })

        if (productDiv.dataset.hitid !== undefined) {
            Search.track(productDiv.dataset.hitid)
        }
        if (url) {
            document.location = url;
        }
    },
    wishlistSendToGA: function (productName, productPrice, productCode, productBrand, productCategory, productVariant, productCurrency) {
        const gtm = {
            event: 'addProductAsFavourite',
            ecommerce: {
                /*currencyCode: productCurrency,*/
                add: {
                    products: [{
                        name: productName,
                        id: productCode,
                        price: productPrice,
                        brand: productBrand,
                        category: productCategory,
                        variant: productVariant,
                        pageType: this.getPageTypeName(),                        
                        dimension2: this.getDimension2(productCode)
                    }]
                }
            }
        };

        GTMEvents.pusheEcommerceObject(gtm);
    },
    getPageTypeName: function () {
        const pageTypeElement = document.querySelector('.js-pagetype');

        if (pageTypeElement !== null && pageTypeElement.value !== null) {
            switch (pageTypeElement.value) {
                case "SearchResultPage":
                case "CategoryPage":
                    return "catalog";
                case "ProductDetailsPage":
                    return "product";
                case "StartPage":
                    return "home";
                default:
                    return "other";
            }
        }
    },
    getDimension1: function () {
        const storeNameElement = document.querySelector('.js-storename');
        if (storeNameElement) {
            return storeNameElement.value;
        }
        return '';
    },
    getDimension2: function (productCode) {
        const storeIdElement = document.querySelector('.js-storeId');
        if (storeIdElement) {
            return 'lpid' + storeIdElement.value.padStart(4, "0") + productCode;
        }
        return '';
    },
    trackPageType: function () {
        let selectedStore = document.querySelector('.js-storename').value;
        let pageType = document.querySelector('.js-gaPageType').value;

        if (!selectedStore || !pageType) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'selectedStore': selectedStore,
        });
    }
};