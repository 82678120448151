import { SearchFlyOut } from './SearchFlyOut.js';

export const MobileMenu = {
    init: function () {
        if (!document.querySelector('.mobilenav-flyin')) {
            return;
        }

        const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
        const backLinks = document.querySelectorAll(".navigation-mobile .slide-menu__backlink");

        backLinks.forEach(function (backLink) {
            if (lang === 'sv') {
                backLink.innerHTML = 'Tillbaka';
            } else {
                backLink.innerHTML = 'Back';
            }
        });
    },
    closeMobileNav: function () {
        if (document.querySelector('.mobilenav-flyin')) {
            document.querySelector('.mobilenav-flyin').classList.remove('expanded');
        }
    },
    toggleMobileNav: function (e) {
        e.preventDefault();
        if (document.querySelector('.mobilenav-flyin')) {
            document.querySelector('.mobilenav-flyin').classList.toggle('expanded');
        }

        if (document.getElementById('mobile-menu').firstElementChild.tagName !== "DIV") {
            new window.SlideMenu(document.getElementById('mobile-menu'), {
                showBackLink: true,
                submenuLinkAfter: ' <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10"><path fill="#100A10" d="M.675 4.059l-.02.004h8.03l-2.524-2.53a.656.656 0 01-.191-.466c0-.176.068-.342.191-.466l.393-.393a.654.654 0 01.928 0l4.327 4.326c.124.124.191.29.191.465a.652.652 0 01-.191.466L7.482 9.792a.652.652 0 01-.464.192.65.65 0 01-.464-.192L6.161 9.4a.651.651 0 010-.918l2.553-2.544H.666A.682.682 0 010 5.264v-.556c0-.362.313-.649.675-.649z"/></svg>'
            });
        }
        
        SearchFlyOut.hideFlyout();
    }
}