import { GTMEvents } from '../src/GTMEvents';
import { ModalPopup } from './Modal';
import { Minicart } from './Minicart';

export const Cart = {
    init: function () {
        document.querySelectorAll(".js-add-to-cart").forEach(function (button) {
            button.addEventListener("click", Cart.addToCart);
        });

        const shareCartButton = document.querySelector('.cartpage__sharecart');
        if (shareCartButton) {
            shareCartButton.addEventListener('click', function (e) {
                e.preventDefault();
                Cart.copyToClipboard(e.target.closest('a').href);

                /* Variable copyToClipboardText is set in CartPage/index.cshtml */
                document.querySelector('.cartpage__sharecart span').innerHTML = copyToClipboardText;
            });
        }
        Cart.showPaymentForm();
    },
    addToCartCanCan: function (e, product, modalHeading, colorInfo, useProductId, quantityToAdd, parentSkuCode) {
        e.preventDefault();

        let quantity = 1;

        if (quantityToAdd) {
            quantity = quantityToAdd;
        }

        fetch('/Cart/AddToCartById', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'skuCode': (product.IsTool || useProductId ? product.Id : product.Code),
                'quantity': quantity,
                'colorInfo': colorInfo ?? '',
                'priceListId': product.PriceListId,
                'parentSkuCode': parentSkuCode ?? '',
            })
        })
            .then((response) => response.json())
            .then((lineitem) => {
                let modal = document.querySelector(".addtocart-modal-popup");
                const heading = modalHeading ?? 'Tillagd';
                let ordinaryPrice = '';
                if (lineitem.DiscountedPrice < lineitem.Price) {
                    ordinaryPrice = `(Ord.pris ${lineitem.Price.toFixed(2)} kr)`;
                }
                
                let markup = `
                <div class="addtocart-modal-popup__inner">
                    <div class="addtocart-modal-popup__innerproductinformation">
                        <div class="addtocart-modal-popup__image">
                            <img src="https://colorama.cdn.storm.io/${lineitem.ImageKey}?width=100&height=100&mode=crop&quality=80" alt="product image" />
                        </div>
                        <div class="addtocart-modal-popup__information">
                            <div class="addtocart-modal-popup__heading">${heading}</div>
                            <div class="product-name">${lineitem.Name}</div>
                            <div class="product-price">${lineitem.DiscountedPrice.toFixed(2)} kr</div>
                        </div>
                    </div>
                    <div class="addtocart-modal-popup__checkout-button">
                        <a href="${lineitem.CheckoutPage}" class="btn btn-green-arrow minicart-tothecart-btn">
                            <span>${lineitem.CheckoutPageLinkText}</span>
                        </a>
                    </div>
                </div>`;
                modal.innerHTML = markup;

                ModalPopup.showAddToCartPopup(modal);

                const orderSampleModal = document.querySelector(".js-ordersample-modal");
                if (orderSampleModal) {
                    ModalPopup.hide(orderSampleModal);
                }
                // Change text on addtocart-button, and change back after 3 seconds
                // var originalText = e.target.innerText;
                // e.target.innerText = e.target.dataset.addedtext;
                // setTimeout(() => e.target.innerText = originalText, 3000);

                Minicart.updateMiniCart();
            })
            .catch((error) => {
                document.getElementById("js-error-popup").classList.add("error-message-popup__enabled");
            });

        GTMEvents.addToCartGTM(quantity);
    },
    addToCart: function (e, code) {
        e.preventDefault();

        const form = e.target.closest('form');
        let colorinfo = document.getElementById('colorinfo').value;
        if (colorinfo === "") {
            colorinfo = e.target.dataset.colorInfo;
        }
        let priceListId = document.getElementById('priceListId').value;

        let quantity = 1;
        if (form !== undefined) {
            quantity = form.querySelector('.quantity-input').value;
        }
        let skuCode = code;
        if (skuCode === undefined) {
            skuCode = e.target.dataset.skuCode;
        }

        let overridePricelistId = e.target.dataset.overridePricelist;
        if (overridePricelistId !== undefined) {
            priceListId = overridePricelistId;
        }

        if (quantity < 1) {
            return;     
        }

        fetch('/Cart/AddToCart', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'skuCode': skuCode,
                'quantity': quantity,
                'colorInfo': colorinfo,
                'priceListId': priceListId
            })
        })
            .then((response) => response.json())
            .then((lineitem) => {
                let modal = document.querySelector(".addtocart-modal-popup");
                const heading = modal.dataset.heading;
                let ordinaryPrice = '';
                if (lineitem.DiscountedPrice < lineitem.Price) {
                    ordinaryPrice = `(Ord.pris ${lineitem.Price.toFixed(2)} kr)`;
                }
                
                let markup = `
                <div class="addtocart-modal-popup__inner">
                    <div class="addtocart-modal-popup__innerproductinformation">
                        <div class="addtocart-modal-popup__image">
                            <img src="https://colorama.cdn.storm.io/${lineitem.ImageKey}?width=100&height=100&mode=crop&quality=80" alt="product image" />
                        </div>
                        <div class="addtocart-modal-popup__information">
                            <div class="addtocart-modal-popup__heading">${heading}</div>
                            <div class="product-name">${lineitem.Name}</div>
                            <div class="product-price">${lineitem.DiscountedPrice.toFixed(2)} kr</div>
                        </div>
                    </div>
                    <div class="addtocart-modal-popup__checkout-button">
                        <a href="${lineitem.CheckoutPage}" class="btn btn-green-arrow minicart-tothecart-btn">
                            <span>${lineitem.CheckoutPageLinkText}</span>
                        </a>
                    </div>
                </div>`;
                modal.innerHTML = markup;

                ModalPopup.showAddToCartPopup(modal);

                const orderSampleModal = document.querySelector(".js-ordersample-modal");
                if (orderSampleModal) {
                    ModalPopup.hide(orderSampleModal);
                }
                // Change text on addtocart-button, and change back after 3 seconds
                var originalText = e.target.innerText;
                e.target.innerText = e.target.dataset.addedtext;
                setTimeout(() => e.target.innerText = originalText, 3000);

                Minicart.updateMiniCart();
            })
            .catch((error) => {
                document.getElementById("js-error-popup").classList.add("error-message-popup__enabled");
            });

        GTMEvents.addToCartGTM(quantity);
    },
    removeFromCart: function () {

    },
    UpdateLineItemQuantityFromSku: function (sku, colorCode, quantity) {
        return fetch('/Cart/updateLineItemQuantityFromSku', {
            method: 'PUT',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'sku': sku,
                'colorcode': colorCode,
                'quantity': quantity
            })
        })
            .then(response => response.json())
            .then(response => response)
            .catch((error) => {                
            });
    },
    updateCart: function () {
        let storeId = document.getElementById('StoreId').value;
        let deliveryMethodPickupId = document.getElementById('DeliveryMethodPickupId').value;
        const paymentContainer = document.querySelector('.cartpage__payment');
        const pickupLocations = document.getElementById('pickup-dropdown');
        const discountCode = document.getElementById('js-cart-page-coupon-input').value;
        const deliveryMethodsLoader = document.getElementById('loadingDeliveryMethods');

        const deliveryMethod = document.querySelector("input[name=deliverymethod]:checked");
        paymentContainer.style.display = 'none';

        let shouldRefreshPayment = true;

        if (!deliveryMethod) {
            // Selected delivery option is required before showing
            // the payment container.
            return;
        }

        document.querySelectorAll('.delivery-method-container').forEach(function (element) {
            element.classList.add('disabled');
        });

        // Adds attribute disabled to quantity-changers in cart, removed when cart is updated
        var quantitybuttons = document.getElementsByClassName("js-quantity-button");
        var i;
        for (i = 0; i < quantitybuttons.length; i++) {
            quantitybuttons[i].setAttribute("disabled", "");
        }
        var quantityinput = document.getElementsByClassName("js-quantity-input");
        for (i = 0; i < quantityinput.length; i++) {
            quantityinput[i].setAttribute("disabled", "");
        }
        if (deliveryMethodsLoader !== null) {
            deliveryMethodsLoader.style.display = "block";
        }
        const deliveryMethodId = deliveryMethod.value;
        if (deliveryMethodId === deliveryMethodPickupId) {
            let selectedLocation = '';
            if (pickupLocations) {
                selectedLocation = pickupLocations.value;

                if (selectedLocation === '') {
                    // Selected pickup location is required before showing
                    // the payment container.
                    shouldRefreshPayment = false;
                }
                else {
                    storeId = selectedLocation;
                }
            }
        }

        const loaderElement = document.querySelector('.loader');
        const totalsElement = document.querySelector('.cartpage__totals');
        if (loaderElement && totalsElement) {
            loaderElement.style.display = "block";
            totalsElement.style.display = "none";
        }

        return fetch('/Cart/UpdateCart', {
            method: 'PUT',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'storeId': storeId,
                'deliveryMethodId': deliveryMethodId,
                'discountCode': discountCode
            })
        })
            .then(response => response.json())
            .then( function (response) {
                Cart.updateTotalCost(response.TotalCostFormatted);
                Cart.updateShippingCost(response.ShippingCost);
                Cart.updateVat(response.VATFormatted);
                Cart.updateActiveCouponValue(response.ActivePromotionValueFormatted);
                Cart.updateCoupon(response.ActivePromotionName);


                if (shouldRefreshPayment) {
                     return CartPage.refreshPaymentForm();
                }
            })
            .then(response => {
                // Remove attribute disabled from quantity-changers in cart
                for (i = 0; i < quantitybuttons.length; i++) {
                    quantitybuttons[i].removeAttribute("disabled");
                }
                for (i = 0; i < quantityinput.length; i++) {
                    quantityinput[i].removeAttribute("disabled");
                }

                document.querySelectorAll('.delivery-method-container').forEach(function (element) {
                    element.classList.remove('disabled');
                });
                if (deliveryMethodsLoader !== null) {
                    deliveryMethodsLoader.style.display = "none";
                }
                if (loaderElement && totalsElement) {
                    loaderElement.style.display = "none";
                    totalsElement.style.display = "block";
                }

            })
            .catch((error) => {                
                paymentContainer.style.display = 'none';
                document.querySelectorAll('.delivery-method-container').forEach(function (element) {
                    element.classList.remove('disabled');
                });
            });
    },
    copyToClipboard: function (str) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    },
    showCouponContainer: function () {
        const couponContainer = document.querySelector('.js-cart-page-coupon-container');
        const modalOverlay = document.querySelector('.cart-modal-container');
        if (couponContainer) {
            couponContainer.style.display = "block";
            modalOverlay.style.display = "block";
        };
    },
    hideCouponContainer: function (shouldClearCouponInput) {
        const couponContainer = document.querySelector('.js-cart-page-coupon-container');
        const modalOverlay = document.querySelector('.cart-modal-container');
        const couponInvalidElement = document.querySelector('.js-cart-page-coupon-invalid');
        const couponInputElement = document.getElementById('js-cart-page-coupon-input');

        if (couponContainer) {
            couponContainer.style.display = "none";
            modalOverlay.style.display = "none";
        };

        if (couponInvalidElement.style.display == 'block') {
            couponInputElement.value = '';
            couponInvalidElement.style.display = 'none'
        }

        if (shouldClearCouponInput) {
            couponInputElement.value = '';
        }

    },
    showCouponButton: function () {
        const showCouponButton = document.querySelector('.js-cart-page-show-coupon-button');
        if (showCouponButton) {
            showCouponButton.style.display = "block";
        };
    },
    hideCouponButton: function () {
        const showCouponButton = document.querySelector('.js-cart-page-show-coupon-button');
        if (showCouponButton) {
            showCouponButton.style.display = "none";
        };
    },
    activateCoupon: function (orderGroupId) {
        const couponContainer = document.querySelector('.js-cart-page-coupon-container');
        const modalOverlay = document.querySelector('.cart-modal-container');
        if (couponContainer) {
            couponContainer.style.display = "none";
            modalOverlay.style.display = "none";
        };

        Cart.updateCart(orderGroupId)
    },
    removeCoupon: function (orderGroupId) {
        const couponActiveElement = document.querySelector('.js-cart-page-coupon-active');
        const couponRemoveButton = document.querySelector('.js-cart-page-remove-promotion');
        const couponNameElement = document.querySelector('.js-cart-page-coupon-name');
        const couponInputElement = document.getElementById('js-cart-page-coupon-input');

        if (couponActiveElement && couponRemoveButton && couponNameElement) {
            couponActiveElement.style.display = "none";
            couponRemoveButton.style.display = "none";
            couponNameElement.innerText = '';
            couponInputElement.value = '';
        }
        Cart.updateCart(orderGroupId);
    },
    showPaymentForm: function () {
        const paymentContainer = document.querySelector('.cartpage__payment');
        const deliveryMethod = document.querySelector("input[name=deliverymethod]:checked");

        if (!paymentContainer || !deliveryMethod) {
            return;
        }
        if (!deliveryMethod) {
            // Selected delivery option is required before showing
            // the payment container.
            paymentContainer.style.display = 'none';
            return;
        }
        paymentContainer.style.display = 'block';
    },
    updateTotalCost: function (cost) {
        const totalCostElement = document.querySelector('.js-cart-page-totalprice');
        if (totalCostElement) {
            totalCostElement.innerText = cost;
        }
    },
    updateShippingCost: function (shippingCost) {
        const shippingCostElement = document.querySelector('.js-cart-page-shipping');
        if (shippingCostElement) {
            shippingCostElement.innerText = shippingCost;
        }
    },
    updateVat: function (vat) {
        const vatCostElement = document.querySelector('.js-cart-page-vat');
        if (vatCostElement) {
            vatCostElement.innerText = vat;
        }
    },
    updateActiveCouponValue: function (couponValue) {
        const totalPromotionContainerElement = document.querySelector('.js-cart-page-totalpromotion-container');
        const totalPromotionElement = document.querySelector('.js-cart-page-totalpromotion');

        if (totalPromotionContainerElement && totalPromotionElement) {
            totalPromotionElement.innerText = couponValue;

            if (couponValue === "0") {
                totalPromotionContainerElement.style.display = "none";
            }
            else {
                totalPromotionContainerElement.style.display = "block";
            }
        }
    },
    updateCoupon: function (couponName) {
        const couponNameElement = document.querySelector('.js-cart-page-coupon-name');
        const couponActiveElement = document.querySelector('.js-cart-page-coupon-active');
        const couponInvalidElement = document.querySelector('.js-cart-page-coupon-invalid');
        const couponRemoveButton = document.querySelector('.js-cart-page-remove-promotion');
        const discountCode = document.getElementById('js-cart-page-coupon-input').value;

        if (couponNameElement && couponActiveElement && couponRemoveButton && couponName) {
            couponNameElement.innerText = couponName;
            couponActiveElement.style.display = "block";
            couponRemoveButton.style.display = "block";
        }
        else {
            document.querySelector('.js-cart-page-coupon-active').style.display = "none";
        }

        Cart.hideCouponContainer();
        couponInvalidElement.style.display = 'none';

        if (couponName !== null) {
            Cart.hideCouponButton();
        }
        else if (couponName === null && discountCode !== "" && couponInvalidElement) {
            couponInvalidElement.style.display = 'block';
            Cart.showCouponContainer();
        }
        else {
            Cart.showCouponButton();
        }
    },

};