export const Tabs = {
    tab_headers: null,
    tab_headers_search: null,
    tab_contents: null,
    tab_contents_search: null,
    current_tab_index: -1,
    default_tab_index: -1,
    current_tab_index_search: -1,
    default_tab_index_search: -1,

    init: function () {
        [...document.querySelectorAll('.search-tabs-container')]
            .forEach(x => Tabs.createTabs(x, 'search'));    

        [...document.querySelectorAll('.login-signup-modal__tabs')]
            .forEach(x => Tabs.createTabs(x));

        [...document.querySelectorAll('.categorypage__tabs')]
            .forEach(x => Tabs.createTabs(x, 'search'));
    },
    createTabs: function (element, area) {
        const header = element.querySelector('.tabs-header');
        const content = element.querySelector('.tabs');

        if (document.getElementsByClassName('tabs-header').length === 0 ||
            document.getElementsByClassName('tabs').length === 0) {
            return;
        }
        if (header === null) {
            return;
        }

        if (area === 'search') {
            Tabs.tab_headers_search = [...header.children];
            Tabs.tab_contents_search = [...content.children];
            Tabs.tab_contents_search.forEach(x => x.style.display = 'none');
            Tabs.default_tab_index_search = Tabs.tab_headers_search.findIndex(x => {
                return x.classList.contains('default-tab');
            });

            Tabs.default_tab_index_search = Tabs.default_tab_index_search === -1 ? 0 : Tabs.default_tab_index_search;
            Tabs.setTabForSearch(Tabs.default_tab_index_search);
            Tabs.tab_headers_search.forEach((x, i) => x.onclick = event => Tabs.setTabForSearch(i, area));
        } else {
            Tabs.tab_headers = [...header.children];
            Tabs.tab_contents = [...content.children];
            Tabs.tab_contents.forEach(x => x.style.display = 'none');

            Tabs.default_tab_index = Tabs.tab_headers.findIndex(x => {
                return x.classList.contains('default-tab');
            });

            Tabs.default_tab_index = Tabs.default_tab_index === -1 ? 0 : Tabs.default_tab_index;
            Tabs.setTab(Tabs.default_tab_index);
            Tabs.tab_headers.forEach((x, i) => x.onclick = event => Tabs.setTab(i, area));
        }

    },
    setTabForSearch: function (index) {
        let tab_headers = Tabs.tab_headers_search;
        let tab_contents = Tabs.tab_contents_search;
        if (Tabs.current_tab_index_search > -1) {
            if (tab_headers.length > Tabs.current_tab_index_search) {
                tab_headers[Tabs.current_tab_index_search].classList.remove('default-tab');
                tab_headers[Tabs.current_tab_index_search].classList.remove('current-tab');
            }
            tab_contents[Tabs.current_tab_index_search].style.display = 'none';
        }

        if (tab_headers.length > index) {
            tab_headers[index].classList.add('current-tab');
        }

        if (index < tab_contents.length)
        {
            tab_contents[index].style.display = 'block';
            Tabs.current_tab_index_search = index;
        }        
    },
    setTab: function (index) {
        let tab_headers = Tabs.tab_headers;
        let tab_contents = Tabs.tab_contents;
        if (Tabs.current_tab_index > -1) {
            if (tab_headers.length > Tabs.current_tab_index) {
                tab_headers[Tabs.current_tab_index].classList.remove('default-tab');
                tab_headers[Tabs.current_tab_index].classList.remove('current-tab');
            }
            tab_contents[Tabs.current_tab_index].style.display = 'none';
        }

        if (tab_headers.length > index) {
            tab_headers[index].classList.add('current-tab');
        }

        tab_contents[index].style.display = 'block';
        Tabs.current_tab_index = index;
    },
    showTabs: function (indicies, selectedTabIndex) {
        if (Tabs.tab_headers) {
            Tabs.tab_headers.forEach(function (tab, index) {
                if (indicies.includes(index)) {
                    Tabs.tab_headers[index].style.display = 'block';
                } else {
                    Tabs.tab_headers[index].style.display = 'none';
                }
            });

            Tabs.setTab(selectedTabIndex);
        }
    }
}