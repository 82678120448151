import { Cookies } from "./Cookies";
import { LinkDropdown } from "./LinkDropdown";

export const StorePicker = {
    searchBar: null,
    regionButton: null,
    dropdownContainer: null,
    selectedRegion: '',
    regionpickerArrow: null,
    id: '#js-store-picker',
    init: function () {
        this.searchBar = document.querySelector(StorePicker.id + ' .store-picker__search');
        this.regionButton = document.querySelector(StorePicker.id + " .js-search-input-button");
        this.dropdownContainer = document.querySelector(StorePicker.id + " .js-dropdown-container");
        this.regionpickerArrow = document.querySelector(StorePicker.id + " .js-regionpicker-arrow");

        if (!this.searchBar ||
            !this.regionButton ||
            !this.dropdownContainer ||
            !this.regionpickerArrow) {
            return;
        }

        let searchpickerinput = document.querySelector(StorePicker.id + ' .store-picker__search');
        searchpickerinput.addEventListener("input", this.search);

        let searchinputbutton = document.querySelector(StorePicker.id + " .js-search-input-button");
        searchinputbutton.addEventListener("click", this.clickDropdownButton);

        let storePickerOverlay = document.querySelector(".store-picker-overlay");
        storePickerOverlay.addEventListener('click', this.clickOutsideDropdown);

        document.addEventListener('click', this.hideDropdown);

        let element = document.querySelector('.choose-store-popup');
        if (element !== null) {
            StorePicker.initStorePopupLocation(element);
        }
    },
    initStorePopupLocation: function (element) {
        var top = parseInt(element.offsetTop , 10);
        if (document.querySelector(".broadcast-message--visible") !== null) {
            top += document.querySelector(".broadcast-message").clientHeight;
        }

        if (document.querySelector(".store-choice")  !== null) {
            top += document.querySelector(".store-choice").clientHeight;
        }
        element.style.top = top + "px";
    },
    SelectNone: function (value) {
        document.querySelector(StorePicker.id + " .js-search-input-button").textContent = value;
        document.querySelector(StorePicker.id + " .js-search-input-button").classList.remove("selectiondone");
        StorePicker.selectedRegion = '';
        StorePicker.search();
    },
    SelectRegion: function (region) {
        StorePicker.selectedRegion = region;
        StorePicker.search();
        document.querySelector(StorePicker.id + " .js-search-input-button").textContent = region;
        document.querySelector(StorePicker.id + " .js-search-input-button").classList.add("selectiondone");
    },
    clickDropdownButton: function (e) {
        if (document.querySelector(StorePicker.id + " .js-dropdown-container").style.display === "block") {
            document.querySelector(StorePicker.id + " .js-dropdown-container").style.display = "none";
        }
        else {
            document.querySelector(StorePicker.id + " .js-dropdown-container").style.display = "block";
        }

        document.querySelector(StorePicker.id + " .js-regionpicker-arrow").classList.toggle('arrow-up');

        e.stopPropagation();
        LinkDropdown.toggle(e);
    },
    clickOutsideDropdown: function () {
        StorePicker.hideDropdown();
        StorePicker.closeStorePicker();
        StorePicker.closeStorePickerWarning();
    },
    hideDropdown: function () {
        document.querySelector(StorePicker.id + " .js-dropdown-container").style.display = "none";
        document.querySelector(StorePicker.id + " .js-regionpicker-arrow").classList.remove('arrow-up');
    },
    openStorePicker: function () {
        document.body.classList.add("overflow-hidden"); 
        if (Cookies.getCookie('OrderGroupId') !== "") {
            document.querySelector('.store-picker-warning').style.display = "block";
            if (window.innerWidth > 600) {
                document.querySelector('.store-picker-overlay').style.display = "block";
            }
            return;
        }
        document.querySelector(StorePicker.id).style.display = "block";
        if (window.innerWidth > 600) {
            document.querySelector('.store-picker-overlay').style.display = "block";
        }
    },
    closeStorePicker: function () {
        document.querySelector(StorePicker.id + '.store-picker').style.display = "none";
        document.querySelector('.store-picker-overlay').style.display = "none";
        document.body.classList.remove("overflow-hidden");
    },
    visibleStores: function () {
        let stores = document.querySelectorAll(StorePicker.id + ' .store-picker-list__item');
        stores.forEach(element => {
            element.style.display = 'block';
        });
        if (StorePicker.selectedRegion === '') {
            return stores;
        }

        stores.forEach(element => {
            element.style.display = 'none';
        });

        let visibleStores = document.querySelectorAll(StorePicker.id + " [data-region='" + StorePicker.selectedRegion + "']");

        visibleStores.forEach(element => {
            element.style.display = 'block';
        });

        return visibleStores;
    },
    search: function (e) {
        let searchInput = document.querySelector(StorePicker.id + ' .store_picker_search_input');

        if (!searchInput) {
            return;
        }

        let noResultElement = document.querySelector(StorePicker.id + ' .store-picker__noresult');
        noResultElement.style.display = "block";

        let searchString = searchInput.value.toLocaleLowerCase();

        let stores = Array.from(StorePicker.visibleStores());

        let sortedStoresByName = stores
            .filter(store => {
                let storeName = store.querySelector(StorePicker.id + ' .store-picker-list__item__name .name').innerHTML.toLocaleLowerCase();

                return storeName.includes(searchString);
            })
            .sort((a, b) => {
                let storeNameA = a.querySelector(StorePicker.id + ' .store-picker-list__item__name .name').innerHTML.toLocaleLowerCase().trim();
                let storeNameB = b.querySelector(StorePicker.id + ' .store-picker-list__item__name .name').innerHTML.toLocaleLowerCase().trim();
                if (storeNameA.indexOf(searchString) > storeNameB.indexOf(searchString.toLowerCase())) {
                    return 1;
                } else if (storeNameA.indexOf(searchString) < storeNameB.indexOf(searchString)) {
                    return -1;
                } else {
                    if (storeNameA > storeNameB)
                        return 1;
                    else
                        return -1;
                }
            });

        let sortedStoresByAddress = stores
            .filter(store => {
                let storeAddress = store.querySelector(StorePicker.id + ' .store-picker-list__item__address');
                if (storeAddress) {
                    storeAddress = storeAddress.innerHTML.toLocaleLowerCase();
                }

                let storeName = store.querySelector(StorePicker.id + ' .store-picker-list__item__name .name').innerHTML.toLocaleLowerCase().trim();

                return storeAddress.includes(searchString) && !storeName.includes(searchString);
            })
            .sort((a, b) => {
                let storeAddressA = a.querySelector(StorePicker.id + ' .store-picker-list__item__address').innerHTML.toLocaleLowerCase();;
                let storeAddressB = b.querySelector(StorePicker.id + ' .store-picker-list__item__address').innerHTML.toLocaleLowerCase();;

                if (storeAddressA.indexOf(searchString) > storeAddressB.indexOf(searchString)) {
                    return 1;
                } else if (storeAddressA.indexOf(searchString) < storeAddressB.indexOf(searchString)) {
                    return -1;
                } else {
                    if (storeAddressA > storeAddressB)
                        return 1;
                    else
                        return -1;
                }
            });

        stores.forEach(store => {
            store.style.display = "none";
        });

        let storePickerList = document.querySelector(StorePicker.id + " .js-store-picker-list");
        sortedStoresByName.forEach(store => {
            store.style.display = "list-item";
            storePickerList.insertBefore(store, null);
        });

        sortedStoresByAddress.forEach(store => {
            store.style.display = "list-item";
            storePickerList.insertBefore(store, null);
        });


        let showNoResult = sortedStoresByName.length === 0 && sortedStoresByAddress.length === 0;

        if (showNoResult) {
            noResultElement.style.display = "block";
        }
        else {
            noResultElement.style.display = "none";
        }
    },
    closeStorePickerWarning: function () {
        document.querySelector('.store-picker-warning').style.display = "none";
        document.querySelector('.store-picker-overlay').style.display = "none";
        document.body.classList.remove("overflow-hidden");
    },
    acceptStorePickerWarning: function () {
        document.querySelector('.store-picker-warning').style.display = "none";
        document.querySelector('.store-picker-overlay').style.display = "none";

        Cookies.setCookie('OrderGroupId', '', 0);
        let productList = document.getElementsByClassName('minicart-productlist')[0];
        if (productList) {
            productList.innerHTML = '';
        }

        let minicartIcon = document.querySelector('.js-minicart-count');
        if (minicartIcon) {
            minicartIcon.classList.remove("js-minicart-count-active");
            minicartIcon.innerHTML = '';
        }

        document.querySelector('.store-picker').style.display = "block";
        if (window.innerWidth > 600) {
            document.querySelector('.store-picker-overlay').style.display = "block";
        }
    },
    hideChooseStorePopup: function () {
        let element = document.querySelector('.choose-store-popup');
        element.style.display = "none";

        document.cookie = "hideStorePopup=true";
    }

}