export const CheckList = {
    backButton: null,
    nextButton: null,
    titleElement: null,
    introElement: null,
    sendEmailElement: null,
    sendEmailTextElement: null,
    resultEmailElement: null,
    resultEmailButtonElement: null,
    emailSuccessElement: null,
    emailErrorElemet: null,
    counter: null,
    questionCounter: null,
    jsQuestionCounter: null,
    answers: null,
    resultAnswers: '',
    modalContent: null,
    uploadPreview: null,
    uploadInput: null,
    completeImagePreview: null,
    showHelpRoute: false,
    navigateStartElement: null,
    btnTabShoppingList: null,
    btnTabPlanningList: null,
    shoppingTabElement: null,
    planningTabElement: null,

    init: function () {
        if (!document.getElementById("js-checklist")) {
            return;
        }
        backButton = document.getElementById("js-checklist-backbutton");
        nextButton = document.getElementById("js-checklist-nextbutton");
        titleElement = document.getElementById("js-checklist_result_title");
        introElement = document.getElementById("js-checklist_result_intro");
        sendEmailElement = document.getElementById("js-checklist_result_send-email");
        sendEmailTextElement = document.getElementById("js-checklist-send-email-text");
        resultEmailElement = document.getElementById('js-checklist_result_email');
        resultEmailButtonElement = document.getElementById('js-checklist_result_email_button');
        emailSuccessElement = document.getElementById('js-checklist_result_send-email-success');
        emailErrorElemet = document.getElementById('js-checklist_result_send-email-error');
        counter = document.getElementById("js-question-counter");
        modalContainer = document.querySelector(".checklistpage__modal");
        openModalButton = document.getElementById("js-open-checklistpage__modal");
        closeModalButton = document.getElementById("js-close-checklistpage__modal");
        modalContent = document.getElementById("js-modal-content");
        uploadPreview = document.getElementById('checklist-uploadpreview');
        uploadInput = document.getElementById('checklistUpload');
        completeImagePreview = document.getElementById('checklist-project-complete-img-preview');
        openModalButton.addEventListener('click', function (e) { CheckList.openModal(); });
        closeModalButton.addEventListener('click', function (e) { CheckList.closeModal(); });
        navigateStartElement = document.getElementById("js-checklist-navigate-start");

        shoppingTabElement = document.getElementById('js-checklist-shoppingtab');
        btnTabShoppingList = document.getElementById("js-checklist-btn-tab-shoppinglist");
        btnTabShoppingList.addEventListener('click', function (e) { CheckList.showShoppingList(); CheckList.hidePlanningList(); });

        planningTabElement = document.getElementById('js-checklist-planningtab');
        btnTabPlanningList = document.getElementById("js-checklist-btn-tab-planninglist");
        btnTabPlanningList.addEventListener('click', function (e) { CheckList.showPlanningList(); CheckList.hideShoppingList(); });

        var inputs = document.querySelectorAll(".checklistpage input[type='checkbox']");
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', function (e) { CheckList.onCheckboxChecked(this) });
        }

        questionCounter = 0;
        jsQuestionCounter = 0;
        answers = {};
        CheckList.showNextQuestion();
        CheckList.loadSession();
        CheckList.updatePlanningTimeTotal();
    },

    sendShareEmail: function () {
        let resultShopping = '';
        let resultPlanning = '';

        var inputs = document.querySelectorAll(".checklistpage .shoppinglistitem input[type='checkbox']");
        for (var i = 0; i < inputs.length; i++) {
            let id = inputs[i].id;
            let title = document.getElementById('shoppinglist-title-' + id).innerText;
            let comment = document.getElementById('shoppinglist-comment-' + id).innerText;
            let input = document.getElementById('shoppinglist-input-' + id).value;
            resultShopping = resultShopping +
                title + ';' +
                comment + ';' +
                input + '|';

        }

        var inputs = document.querySelectorAll(".checklistpage .planninglistitem input[type='checkbox']");
        for (var i = 0; i < inputs.length; i++) {
            let id = inputs[i].id;
            let title = document.getElementById('planninglist-title-' + id).innerText;
            let comment = document.getElementById('planninglist-comment-' + id).innerText;
            let time = document.getElementById('planninglist-time-' + id).value;
            // let date = document.getElementById('planninglist-date-' + id).value; // todo will be added back later
            resultPlanning = resultPlanning +
                title + ';' +
                comment + ';' +
                time + ';'; // +
            // date + '|';
        }

        let email = resultEmailElement.value;
        if (email === null || email === undefined || email === '') {
            return;
        }

        let url = window.location.pathname.replace(/\/?$/, '/') + "sendshareemail";
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'email': email,
                'resultShopping': resultShopping,
                'resultPlanning': resultPlanning,
            })
        })
            .then(response => {
                resultEmailElement.style.display = "none";
                resultEmailButtonElement.style.display = "none";
                sendEmailTextElement.style.display = "none";

                if (!response.ok) {
                    emailErrorElemet.style.display = "block";
                }
                else {
                    emailSuccessElement.style.display = "block";
                }
            })
            .catch((error) => {
                emailErrorElemet.style.display = "block";
            });
    },
    shoppingItemReduce: function (id) {
        let input = document.getElementById('shoppinglist-input-' + id);
        if (input) {
            if (input.value > 0) {
                input.value = parseInt(input.value) - 1;
            }
            CheckList.setReduceBtnOpacity(id);
        }
    },
    shoppingItemIncrease: function (id) {
        let input = document.getElementById('shoppinglist-input-' + id);
        if (input) {
            if (input.value === "") {
                input.value = 1;
            }
            else {
                input.value = parseInt(input.value) + 1;
            }

            CheckList.setReduceBtnOpacity(id);
        }
    },
    setReduceBtnOpacity: function (id) {
        let input = document.getElementById('shoppinglist-input-' + id);
        let btnReduce = document.getElementById('shoppinglist-btn-reduce-' + id);

        if (input.value > 0) {
            if (btnReduce) {
                btnReduce.style.opacity = "1";
            }
        }
        else {
            if (btnReduce) {
                btnReduce.style.opacity = "0.5";
            }
        }
    },
    onCheckboxChecked: function (e) {
        let commentElement = document.getElementById('shoppinglist-comment-' + e.id);
        if (commentElement === null) { // try planninglist
            commentElement = document.getElementById('planninglist-comment-' + e.id);
        }
        if (commentElement) {
            if (commentElement.style.opacity === "0.5") {
                commentElement.style.opacity = "1";
            }
            else {
                commentElement.style.opacity = "0.5";
            }
        }
    },
    showShoppingList: function () {
        shoppingTabElement.style.display = "block";
        btnTabShoppingList.classList.remove("btn-green");
        btnTabShoppingList.classList.add("btn-white");
        btnTabShoppingList.classList.add("btn-green-outline");
    },
    hideShoppingList: function () {
        shoppingTabElement.style.display = "none";
        btnTabShoppingList.classList.add("btn-green");
        btnTabShoppingList.classList.remove("btn-white");
        btnTabShoppingList.classList.remove("btn-green-outline");
    },
    showPlanningList: function () {
        planningTabElement.style.display = "block";
        btnTabPlanningList.classList.remove("btn-green");
        btnTabPlanningList.classList.add("btn-white");
        btnTabPlanningList.classList.add("btn-green-outline");
    },
    hidePlanningList: function () {
        planningTabElement.style.display = "none";
        btnTabPlanningList.classList.add("btn-green");
        btnTabPlanningList.classList.remove("btn-white");
        btnTabPlanningList.classList.remove("btn-green-outline");
    },

    openModal: function () {
        modalContainer.classList.add("modal-open");
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
        navigateStartElement.style.display = "none";
    },

    closeModal: function () {
        modalContainer.classList.remove("modal-open");
        document.getElementsByTagName("html")[0].style.overflow = "initial";
        document.getElementsByTagName("html")[0].style.overflowX = "hidden";
        navigateStartElement.style.display = "flex";
    },

    clickAnswer: function (type, value, e) {        
        answers[type] = value;

        let questionContent = e.parentElement.parentElement.parentElement.getElementsByClassName("question__heading")[0].textContent;
        let answer = e.getElementsByTagName("p")[0].textContent;
        this.resultAnswers = this.resultAnswers + '\n' + questionContent + ": " + answer;

        if (value === "helparea") {
            CheckList.showHelpRoute = true;
        }
        CheckList.showNextQuestion();
    },

    showNextQuestion: function () {
        backButton.style.display = "none";

        if (questionCounter > 0) {
            backButton.style.display = "inline-block";
        }

        CheckList.hideQuestion();

        let question = CheckList.currentQuestion();
        if (question && question.dataset.type === "step0" && CheckList.showHelpRoute) {
            questionCounter = 3;
            jsQuestionCounter = 3;
        }
        else if (question && question.dataset.type === "step1") {
            questionCounter = 8;
            jsQuestionCounter = 8;
        }
        else {
            questionCounter++;
            jsQuestionCounter++;
        }
        CheckList.showQuestionBackgroundImage(jsQuestionCounter);
        CheckList.showQuestion();

        if (CheckList.currentQuestion() === null) {
            CheckList.showResult();
        }
        CheckList.updateNextButtonText();
    },

    showPreviousQuestion: function () {
        CheckList.hideResult();
        CheckList.hideQuestion();
        if (CheckList.showHelpRoute && questionCounter === 4) {
            questionCounter = 2;
            jsQuestionCounter = 2;
            CheckList.showHelpRoute = false;
        }
        else if (!CheckList.showHelpRoute && questionCounter === 8) {
            questionCounter = 3;
            QuestionCounter = 3;
        }
        else {
            questionCounter--;
            jsQuestionCounter--;
        }

        backButton.style.display = "none";

        if (questionCounter > 1) {
            backButton.style.display = "inline-block";
        }

        CheckList.showQuestionBackgroundImage(jsQuestionCounter);
        CheckList.showQuestion();
        CheckList.updateNextButtonText();
    },

    showQuestionBackgroundImage: function (id) {
        let questionBackgroundImage = document.getElementById('js-question-image-' + id);
        if (questionBackgroundImage !== null) {
            modalContent.style.backgroundImage = "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('" + questionBackgroundImage.value + "')";
        }
        else {
            modalContent.style.backgroundImage = "";
        }
    },
    updateNextButtonText: function () {
        nextButton.disabled = false;
        nextButton.innerHTML = '<span>Kom igång</span>';
        nextButton.classList.add("btn-green")
        nextButton.classList.add("btn-green-arrow")
        nextButton.classList.remove("btn-green-outline")

        if (questionCounter > 1) {
            nextButton.innerHTML = '<span>Nästa</span>';
            nextButton.classList.remove("btn-green-arrow")
        }

        let question = CheckList.currentQuestion();
        if (question) {
            let step = question.dataset.type;
            if (step == "step7") { // image upload
                nextButton.disabled = true;
                nextButton.innerHTML = "Klar"
                nextButton.classList.remove("btn-green-arrow")

                if (uploadInput) {
                    nextButton.disabled = uploadInput.value === '';
                    if (nextButton.disabled === true) {
                        nextButton.classList.remove("btn-green")
                        nextButton.classList.add("btn-green-outline")
                    }
                }
            }
        }

    },

    hideQuestion: function () {
        let question = CheckList.currentQuestion();
        if (question) {
            question.style.display = "none";
        }
    },

    showQuestion: function () {
        let question = CheckList.currentQuestion();
        if (question) {
            question.style.display = "block";

            let navigateElement = document.getElementById("js-checklist-navigate");
            let step = question.dataset.type;

            if (navigateElement &&
                (
                    step == "step1" ||
                    step == "step2" ||
                    step == "step3" ||
                    step == "step4" ||
                    step == "step5" ||
                    step == "no-input" ||
                    step == "step6" ||
                    step == "step7"
                )
            ) {
                navigateElement.style.display = "flex";
            }
            else {
                navigateElement.style.display = "none";
            }
        }
    },

    currentQuestion: function () {
        return document.getElementById("question-" + questionCounter);
    },

    hideResult: function () {
        titleElement.style.display = "none";
        introElement.style.display = "none";
        sendEmailElement.style.display = "none";
    },
    calculateSides: function () {
        let xInput = document.getElementById("step2x");
        let yInput = document.getElementById("step2y");
        let sumInput = document.getElementById("step2sum");
        if (xInput && yInput && sumInput) {
            let x = xInput.value;
            let y = yInput.value;
            let sum = x * y;
            if (!isNaN(sum)) {
                sumInput.value = (sum).toFixed(1);
            }

            let sidesInput = document.getElementById("step5sides");
            if (sidesInput) {
                sidesInput.value = (sum).toFixed(1);
                CheckList.calculateSum();
            }
        }
    },
    calculateDoorsAndWindows: function () {
        let xInput = document.getElementById("step4x");
        let yInput = document.getElementById("step4y");
        let sumInput = document.getElementById("step4sum");
        if (xInput && yInput && sumInput) {
            let x = xInput.value;
            let y = yInput.value;
            let sum = x * y;
            if (!isNaN(sum)) {
                sumInput.value = (sum).toFixed(1);
            }
            let windowsAndDoorsInput = document.getElementById("step5windowsanddoors");
            if (windowsAndDoorsInput) {
                windowsAndDoorsInput.value = (sum * 0.001).toFixed(1);
                CheckList.calculateSum();
            }
        }
    },
    calculateGables: function () {
        let xInput = document.getElementById("step3x");
        let yInput = document.getElementById("step3y");
        let gablesInput = document.getElementById("step3gables");
        gablesInput.defaultValue = 2;
        let sumInput = document.getElementById("step3sum");
        if (xInput && yInput && gablesInput && sumInput) {
            let x = xInput.value;
            let y = yInput.value;
            let gables = gablesInput.value;
            let sum = ((x * y) / 2) * gables;
            if (!isNaN(sum)) {
                sumInput.value = (sum).toFixed(1);
            }

            let gablesSumInput = document.getElementById("step5gables");
            if (gablesSumInput) {
                gablesSumInput.value = (sum).toFixed(1);
                CheckList.calculateSum();
            }
        }
    },
    calculateSum: function () {
        let sidesInput = document.getElementById("step5sides");
        let gablesInput = document.getElementById("step5gables");
        let windowsAndDoorsInput = document.getElementById("step5windowsanddoors");
        let sumInput = document.getElementById("step5sum");
        if (sidesInput && gablesInput && windowsAndDoorsInput && sumInput) {
            let sides = sidesInput.value;
            let gables = gablesInput.value;
            let windowsAndDoors = windowsAndDoorsInput.value;
            let sum = parseInt(sides) + parseInt(gables) + parseInt(windowsAndDoors);
            if (!isNaN(sum)) {
                sumInput.value = sum;
            }

            let step5questionHeading = document.querySelectorAll("[data-type='step5']")[0].querySelector('.question__heading');;
            if (step5questionHeading) {
                step5questionHeading.innerHTML = step5questionHeading.innerHTML.replace("[sum]", "<span id='js-sum'></span>");
                let sumSpan = document.getElementById("js-sum");
                if (sumSpan) {
                    sumSpan.innerHTML = sum + " m2";
                }
            }
        }
    },

    showResult: function () {
        titleElement.style.display = "block";
        introElement.style.display = "block";
        sendEmailElement.style.display = "block";
        counter.style.display = "none";
    },
    imageUploadPreview: function (input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                document.getElementById('checklist-img-preview').src = e.target.result;
                document.getElementById('checklist-project-complete-img-preview').src = e.target.result;
                document.getElementById('checklist-img-filename').innerHTML = input.value.split("\\").pop();

                if (completeImagePreview) {
                    completeImagePreview.src = e.target.result;
                    completeImagePreview.style.display = "block";
                }
            }

            reader.readAsDataURL(input.files[0]);
        }

        if (uploadPreview) {
            uploadPreview.style.display = 'flex';
        }

        if (nextButton) {
            nextButton.disabled = false;
            nextButton.classList.add("btn-green")
            nextButton.classList.remove("btn-green-outline")
        }
    },
    clearImageUpload: function () {
        if (uploadInput) {
            uploadInput.value = '';
        }
        if (uploadPreview) {
            uploadPreview.style.display = 'none';
        }

        if (completeImagePreview) {
            completeImagePreview.src = "";
            completeImagePreview.style.display = "none";
        }

        if (nextButton) {
            nextButton.disabled = true;
            nextButton.classList.remove("btn-green")
            nextButton.classList.add("btn-green-outline")
        }
    },
    updateProjectName: function (input) {
        let nameElement = document.getElementById('checklist-project-complete-name');

        if (nameElement) {
            nameElement.innerHTML = input.value;
        }
    },
    updatePlanningTimeTotal: function () {
        let totalTime = 0;

        let planningTimeTotalEl = document.getElementById('js-planninglist-total');
        planningTimeTotalEl.value = '';

        var inputs = document.querySelectorAll(".js-planninglist-time-item");
        for (var i = 0; i < inputs.length; i++) {
            let time = parseInt(inputs[i].value);
            if (Number.isInteger(time)) {
                totalTime = totalTime + time;
            }
        }

        planningTimeTotalEl.innerText = totalTime;
    },
    loadSession: function () {
        var inputs = document.querySelectorAll(".js-session-input");

        for (var i = 0; i < inputs.length; i++) {
            let id = inputs[i].id;
            let inputType = inputs[i].type
            let sessionValue = sessionStorage.getItem(id);

            if (inputType === 'checkbox') {
                inputs[i].checked = (sessionValue === 'true');
            }

            else if (inputType === 'number') {
                inputs[i].value = sessionValue;
            }
        }
    },
    updateSessionCheckbox: function (id) {
        let input = document.getElementById(id);
        sessionStorage.setItem(id, input.checked);
    },
    updateSessionNumber: function (id) {
        let input = document.getElementById(id);
        let newValue = input.value;
        sessionStorage.setItem(id, newValue);
    },
    resetChecklistSendEmail: function () {
        resultEmailElement.style.display = "block";
        resultEmailButtonElement.style.display = "inline-block";
        sendEmailTextElement.style.display = "block";

        emailErrorElemet.style.display = "none";
        emailSuccessElement.style.display = "none";
    }
}