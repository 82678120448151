export const Accordions = {
    init: function () {

        var footerBlocks = document.querySelectorAll('footer .accordion');
        var productDetailsBlocks = document.querySelectorAll('.product-content .accordion');

        if (window.innerWidth < 576) {
            for (var i = 0; i < footerBlocks.length; i++) {
                if(footerBlocks[i].classList.contains("textblock")){
                    continue;
                }
                footerBlocks[i].classList.add('accordion-item');
            }
        }

        for (var i = 0; i < productDetailsBlocks.length; i++) {
            productDetailsBlocks[i].classList.add('accordion-item');
        }

        var headers = document.querySelectorAll('.accordion-heading');
        if (headers.length > 0) {
            headers.forEach(function (head) {
                head.addEventListener('click', Accordions.toggle);
            });
        }

        let pdpDescAccordion = document.getElementById('jsPdpDescAccordion');
        if(pdpDescAccordion){
            pdpDescAccordion.click();
        }
    },
    toggle: function (e) {
        var accHeading = this;
        var accContent = this.nextElementSibling;
        accHeading.classList.toggle('accordion-active');

        if (!accContent.classList.contains('accordion-visible')) {
            accContent.classList.add('accordion-visible');
            accContent.style.height = 'auto';

            var height = accContent.clientHeight + 'px';

            accContent.style.height = '0px';

            setTimeout(function () {
                accContent.style.height = height;
            }, 0);

        } else {
            accContent.style.height = '0px';

            accContent.addEventListener('transitionend', function () {
                accContent.classList.remove('accordion-visible');
            }, {
                once: true
            });
        }
    }
}
