import { tns } from 'tiny-slider';

export const ProductListingSlideshow = {
    init: function () {
        if (!document.querySelector('.product-list-item')) {
            return;
        }

        const blocks = document.querySelectorAll('.productlistingblock');

        blocks.forEach(block => {
            let container = '#' + block.id + ' .productlistingblock__slideshow .product-listing__collection .row';
            let prev = "#prev-" + block.id;
            let next = "#next-" + block.id;

            try {
                let slider = tns({
                    container: container,
                    prevButton: prev,
                    nextButton: next,
                    fixedWidth: 280,
                    responsive: {
                        700: {
                            fixedWidth: 284
                        },
                        1024: {
                            fixedWidth: 270
                        },
                        1440: {
                            fixedWidth: 310
                        }
                    },
                });
            }
            catch { }
        });
    }
};