export const BuyBlockModal = {
    init: function () {
        const block = document.querySelectorAll('.imagewithbuyblock');
        const modals = document.querySelectorAll('.imagewithbuyblock .modal-container');

        block.forEach(function (item) {
            item.addEventListener('click', BuyBlockModal.openModal);
        });

        modals.forEach(function (modal) {
             modal.addEventListener('click', BuyBlockModal.closeModal);
        });
    },
    openModal: function (e) {
        let thisModal = e.currentTarget.querySelector('.modal-container');
        let thisModalOverlay = e.currentTarget.querySelector('.modal-overlay');

        thisModal.style.display = 'block';
        thisModalOverlay.style.display = 'block';

        // stop scrolling
        document.body.classList.add("overflow-hidden");

    },
    closeModal: function (e) {
        const buyBlocks = document.getElementsByClassName('imagewithbuyblock');

        for (var i = 0; i < buyBlocks.length; i++) {
            let modals = buyBlocks[i].getElementsByClassName('modal-container');
            let modaloverlays = buyBlocks[i].getElementsByClassName('modal-overlay');

            for (let i = 0; i < modals.length; i++) {
                modals[i].style.display = 'none';
            }

            for (let i = 0; i < modaloverlays.length; i++) {
                modaloverlays[i].style.display = 'none';
            }
        }
        document.body.classList.remove("overflow-hidden");

        e.stopPropagation();
    }
};