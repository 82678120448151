import { Cookies } from '../src/Cookies';
import { GTMEvents } from './GTMEvents';

export const Wishlist = {
    init: function () {
        this.update();        
    },
    add: function (e, id) {        
        let element = document.getElementById(id);
        if (!element) {
            return;
        }

        e.stopPropagation();

        let productId = element.dataset.productId;
        if (typeof (productId) === 'undefined') {
            productId = element.parentNode.dataset.productId;
        }
        let productName = element.dataset.productName;
        let productPrice = element.dataset.productPrice;
        let productCode = element.dataset.productCode;
        let productBrand = element.dataset.productBrand;
        let productCategory = element.dataset.productCategory;
        let productVariant = element.dataset.productVariant;
        let productCurrency = element.dataset.productCurrency;

        let productsInCookie = this.productsInCookie();

        if (productsInCookie.includes(productId)) {
            productsInCookie = productsInCookie.replace("," + productId + ",", ",");
        }
        else {
            productsInCookie = productsInCookie + productId + ",";

            GTMEvents.wishlistSendToGA(productName, productPrice, productCode, productBrand, productCategory, productVariant, productCurrency);
        }

        Cookies.setCookie('wishlist', productsInCookie, 365, 'Lax');

        this.update();
    },
    addCanCan: function (e, product) {
        if (!product) {
            return;
        }

        e.stopPropagation();
        
        let added = false;

        let productsInCookie = this.productsInCookie();

        if (productsInCookie.includes(product.Id)) {
            productsInCookie = productsInCookie.replace("," + product.Id + ",", ",");
            added = false;
        }
        else {
            productsInCookie = productsInCookie + product.Id + ",";
            added = true;
            GTMEvents.wishlistSendToGA(product.Name, '', product.Code, product.Brand, product.Category, 'CanCan', '');
        }

        Cookies.setCookie('wishlist', productsInCookie, 365, 'Lax');

        this.update();

        return added;
    },
    update: function () {
        // clear
        document.querySelectorAll("[data-product-id").forEach(product => {
            this.fill(product, "");
        });

        let count = 0;
        // highlight heart
        this.productsInCookie().split(',').forEach(product => {
            if (product !== '' && product !== 'undefined') {
                var matchingProducts = document.querySelectorAll("[data-product-id='" + product + "']")
                matchingProducts.forEach(matchingProduct => {
                    this.fill(matchingProduct, "#3F4B4E");
                })
                count++;
            }
        });
        this.updateWishlistIcon(count);

        this.updateFlyIn();
    },
    updateFlyIn: function () {
        fetch('/wishlist/products')
            .then(response => response.text()
                .then(data => this.updateFlyInHearts(data)));
    },
    updateFlyInHearts: function (data) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(data, 'text/html');

        // highlight heart
        this.productsInCookie().split(',').forEach(product => {
            if (product !== '') {
                var matchingProducts = doc.querySelectorAll("[data-product-id='" + product + "']")
                matchingProducts.forEach(matchingProduct => {
                    this.fill(matchingProduct, "#3F4B4E");
                })
            }
        });

        if (document.getElementById('js-wishlist-products')) {
            document.getElementById('js-wishlist-products').innerHTML = doc.documentElement.innerHTML;
        }
    },
    fill: function(e, color) {
        let element = e.querySelector('.product-list-item__heart path');
        if (element !== null) {
            element.style.fill = color;
            element.style.stroke = color;
        }

    },
    productsInCookie: function() {
        var productsInCookie = Cookies.getCookie('wishlist');
        if (productsInCookie === '')
            productsInCookie = ',';

        return productsInCookie;
    },
    updateWishlistIcon: function (count) {
        // update wishlist icon count
        let wishlistCounter = document.querySelector('.js-wishlist-count');
        if (wishlistCounter === null) {
            return;
        }

        if (count > 0) {
            wishlistCounter.classList.add("js-wishlist-count-active");
            wishlistCounter.innerHTML = count;
        }
        else if (wishlistCounter && count === 0) {
            wishlistCounter.classList.remove("js-wishlist-count-active");
            wishlistCounter.innerHTML = '';
        }
    }
}