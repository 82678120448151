export const Navigation = {
    showSearchBarAfterScroll: false,
    navBar:false,
    storeChoiceEl: null,
    searchBarEl: null,
    toggleSearchBarBtn: null,
    navigationContainerEl: null,
    navBarH: null,
    storeChoiceElH: null,
    pageMain: null,

    init: function () {
        Navigation.navBar = document.getElementById("navbar");
		Navigation.nav = document.querySelector(".navigation");
        Navigation.navBarH = Navigation.navBar?.offsetHeight;
        Navigation.storeChoiceEl = document.querySelector(".jsStoreChoice");
        Navigation.storeChoiceElH = Navigation.storeChoiceEl?.offsetHeight;
        Navigation.searchBarEl = document.querySelector(".jsSearchbar");
        Navigation.toggleSearchBarBtn = document.querySelector(".js-toggle-searchbar");
        Navigation.navigationContainerEl = document.querySelector(".navigation-container");
        Navigation.pageMain = document.querySelector('body > div.main');

        if (!Navigation.navBar ||
            !Navigation.storeChoiceEl ||
            !Navigation.searchBarEl ||
            !Navigation.toggleSearchBarBtn ||
            !Navigation.navigationContainerEl) {
            return;
        }

        Navigation.toggleSearchBarBtn.addEventListener("click", Navigation.toggleSearchBar);

		// Sticky functionality
        Navigation.toggleScrollMenu( window.pageYOffset );

		window.addEventListener( 'scroll', function() {
			let newDistance = window.pageYOffset;
			Navigation.toggleScrollMenu( newDistance );
		});

    },
    toggleScrollMenu: function( scrollDistance ) {
        if (window.innerWidth > 767 || // not mobile
            scrollDistance < Navigation.storeChoiceElH ) { // at top
            Navigation.reset();
            document.querySelector(".jsSearchIcon").classList.remove('fade-in');  
        }

        else {
            document.querySelector(".jsSearchIcon").classList.add('fade-in');  

            Navigation.navBar.classList.add('sticky');            
            Navigation.navBarH = Navigation.navBar.offsetHeight;
            Navigation.pageMain.style.paddingTop = Navigation.navBarH + 'px';
            Navigation.toggleSearchBarBtn.style.visibility = "visible";

            // hide searchbar unless toggled from button
            if (Navigation.showSearchBarAfterScroll) {
                Navigation.searchBarEl.style.display = "block";
                Navigation.searchBarEl.style.height = "40px";
                Navigation.navigationContainerEl.classList.remove("navigation-container__nosearchbar");
            }
            else {
                Navigation.searchBarEl.style.display = "none";
                Navigation.searchBarEl.style.height = "0px";
                Navigation.navigationContainerEl.classList.add("navigation-container__nosearchbar");
            }
        }
    },

    reset: function () {
        Navigation.navBar.classList.remove('sticky');
        Navigation.storeChoiceEl.classList.remove('storechoice-hidden');
        Navigation.pageMain.style.paddingTop = '0px';

        Navigation.searchBarEl.style.display = "block";
        Navigation.searchBarEl.style.height = "40px";
        Navigation.toggleSearchBarBtn.style.visibility = "hidden";
        Navigation.navigationContainerEl.classList.remove("navigation-container__nosearchbar");

        Navigation.showSearchBarAfterScroll = false;
    },
    toggleSearchBar: function (e) {
        e.preventDefault();

        if (Navigation.searchBarEl.style.display === "none") {
            Navigation.searchBarEl.style.display = "block";
            Navigation.searchBarEl.style.height = "40px";
            Navigation.searchBarEl.classList.add("toggle-content");
            Navigation.navigationContainerEl.classList.remove("navigation-container__nosearchbar");
            Navigation.showSearchBarAfterScroll = true;

            // https://jsfiddle.net/cferdinandi/qgpxvhhb/18/
            // Get the natural height of the element
            var getHeight = function () {
                Navigation.searchBarEl.style.display = 'block'; // Make it visible
                var height = Navigation.searchBarEl.scrollHeight + 'px'; // Get it's height
                Navigation.searchBarEl.style.display = ''; //  Hide it again
                return height;
            };

            var height = getHeight(); // Get the natural height
            Navigation.searchBarEl.classList.add('is-visible'); // Make the element visible
            Navigation.searchBarEl.style.height = height; // Update the max-height

            // Once the transition is complete, remove the inline max-height so the content can scale responsively
            window.setTimeout(function () {
                Navigation.searchBarEl.style.height = '';
            }, 350);

        }
        else {
            Navigation.searchBarEl.style.display = "none";
            Navigation.showSearchBarAfterScroll = false;
            Navigation.searchBarEl.classList.remove("toggle-content");
            Navigation.navigationContainerEl.classList.add("navigation-container__nosearchbar");

            // Give the element a height to change from
            Navigation.searchBarEl.style.height = Navigation.searchBarEl.scrollHeight + 'px';

            // Set the height back to 0
            window.setTimeout(function () {
                Navigation.searchBarEl.style.height = '0';
            }, 1);

            // When the transition is complete, hide it
            window.setTimeout(function () {
                Navigation.searchBarEl.classList.remove('is-visible');
            }, 0);
        }
    },
}