export const ConfirmStorePopup = {
    popup:null,
    init: function () {
        ConfirmStorePopup.popup = document.getElementById("js-confirm-store-popup");

        if (ConfirmStorePopup.popup) {
            document.addEventListener('click', function (e) {
                ConfirmStorePopup.clickOutsideConfirmStorePopup(e);
            });
        }
    },
    close: function () {
        if (ConfirmStorePopup.popup) {
            ConfirmStorePopup.popup.classList.remove("confirm-store-popup__enabled");
        }
    },
    clickOutsideConfirmStorePopup: function (e) {
        if (ConfirmStorePopup.popup) {
            if (ConfirmStorePopup.popup.contains(e.target) == false) {
                ConfirmStorePopup.close();
            }
        }
    }
}