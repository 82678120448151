import { GTMEvents } from "./GTMEvents";

export const LoadMoreProducts = {
    init: function () {
        this.loader = document.querySelector('.jsProductsLoader');

        if (!this.loader) {
            return;
        }

        this.pagesTab = document.getElementById("jsPagesTab");
    },
    loader: null,
    pagesTab: null,
    fetchingNewPageProducts: false,
    lastPageProducts: false,
    load: function (e, elementId) {
        e.preventDefault();
        LoadMoreProducts.showLoader();
        const page = parseInt(e.target.dataset.page) + 1;
        
        let query = "";
        if (e.target.dataset.query !== "") {
            query = "&q=" + e.target.dataset.query;
        }

        let filter = '';
        const filterButton = document.getElementById('filterButton');

        let orderby = "";
        if (e.target.dataset.orderby !== "") {
            orderby = "&orderby=" + e.target.dataset.orderby;
        }

        if (filterButton) {
            const currentHref = filterButton.href;
            const params = new URLSearchParams(currentHref);
            let filters = params.getAll("f");
            for (var i = 0; i < filters.length; i++) {
                filter = `${filter}&f=${encodeURIComponent(filters[i])}`;
            }
        }

        let currentBlock = ''
        if (e.target.dataset.id !== "") {
            currentBlock = "&currentBlock=" + e.target.dataset.id;
        }

        const url = e.target.dataset.url + "?page=" + page + query + filter + orderby + currentBlock;
        let productList = document.querySelector('.product-listing');

        if (elementId) {
            productList = document.getElementById(elementId);
        }


        let productsInList = document.querySelectorAll('.product-list-item__inner');
        let productsIds = '';
        productsInList.forEach(function (product) {
            productsIds += product.dataset.productId + ",";
        });

        let pagingAmount = parseInt(e.target.dataset.pagingAmount);

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productsIds: productsIds })
        })
            .then(response => response.text())
            .then(html => {
                if (html && html.indexOf('product-list-item') > -1) {
                    e.target.dataset.page = page;
                    productList.innerHTML += html;

                    var parser = new DOMParser();
                    var doc = parser.parseFromString(html, "text/html");
                    var el = doc.querySelector('.product-listing__collection');
                    let productsAmount = parseInt(el.dataset.amount);
                    if (productsAmount < pagingAmount) {
                        e.target.classList.add('d-none');
                    }

                    GTMEvents.pushProductImpressionAndOrDetails();
                } else {
                    e.target.classList.add('d-none');
                    LoadMoreProducts.lastPageProducts = true;
                }

                LoadMoreProducts.fetchingNewPageProducts = false;
                LoadMoreProducts.hideLoader();
            })
            .catch((error) => {                
            });

        window.history.pushState(null, "", window.location.href.replace(/&p=\d+/, "") + "&p=" + page);
    },
    showLoader: function () {
        if (LoadMoreProducts.loader) {
            LoadMoreProducts.loader.style.display = 'block';
        }
    },
    hideLoader: function () {
        if (LoadMoreProducts.loader) {
            LoadMoreProducts.loader.style.display = 'none';
        }
    },
};