export const RollsCalculator = {

    init: function () {
        if (!document.getElementById('rollscalculator-modal')) {
            return;
        }
        document.getElementById('calculate-rolls-btn').onclick = this.calculate;
    },

    calculate: function () {
        const areaLength = document.getElementById('area-length').value.replace(',', '.');
        const length = parseFloat(areaLength);
        const rollWidth = parseInt(document.getElementById('area-width').value);
        const result = document.getElementById('rolls-result');

        if (length && !Number.isNaN(length) && length > 0) {
            let rolls = length * rollWidth;
            if (Number.isNaN(rolls)) {
                rolls = 0;
            }
            const rollsRoundedUp = Math.ceil(rolls);
            const message = document.getElementById('rolls-result-message').value;
            const area = document.getElementById('rolls-result-area').value;
            const resultMessage = `${message} ${rollsRoundedUp} ${area}`;
            result.innerText = resultMessage;
            result.classList.remove('d-none');
        } else {
            result.classList.add('d-none');
        }
    }
};