export const LoadMorePages = {
    init: function () {
        this.loadMoreBtn = document.querySelector('.js-loadmore-pages');
        this.loader = document.querySelector('.jsPagesLoader');

        if (!this.loadMoreBtn || !this.loader) {
            return;
        }

        this.productsTab = document.getElementById("jsProductsTab");
        this.loadMoreBtn.addEventListener('click', this.load);
    },
    loader: null,
    loadMoreBtn: null,
    productsTab: null,
    fetchingNewPagePages: false,
    lastPagePages: false,
    load: function (e) {
        e.preventDefault();
        LoadMorePages.showLoader();
        const page = parseInt(e.target.dataset.page) + 1;
        let query = "";
        if (e.target.dataset.query !== "") {
            query = "&q=" + e.target.dataset.query;
        }

        const filter = '&f=';
        let orderby = '';
        if (e.target.dataset.orderby !== "") {
            orderby = "&orderby=" + e.target.dataset.orderby;
        }
        const url = e.target.dataset.url + "?page=" + page + query + filter + orderby;
        const productList = document.querySelector('.pages-listing');

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/html',
                'Accept': 'text/html'
            }
        })
            .then(response => response.text())
            .then(html => {
                if (html && html.indexOf('teaser-inner') > -1) {
                    e.target.dataset.page = page;
                    productList.innerHTML += html;
                } else {
                    e.target.classList.add('d-none');
                    LoadMorePages.lastPagePages = true;
                }

                LoadMorePages.fetchingNewPagePages = false;
                LoadMorePages.hideLoader();
            })
            .catch((error) => {                
            });
    },
    showLoader: function () {
        LoadMorePages.loader.style.display = 'block';
    },
    hideLoader: function () {
        LoadMorePages.loader.style.display = 'none';
    },
};