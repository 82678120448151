export const MainMenu = {
    init: function () {
        var MainMenuToggle = {};
    },
    hideMenu: function() {
        document.querySelectorAll(".level2")
            .forEach(element => element.style.display = "none");    
        document.querySelector("#nav-overlay").style.display = "none";           
       
    },
    showLevel1: function(id) {
        // hide all level2
        document.querySelectorAll(".level2")
            .forEach(element => element.style.display = "none");       

        // hide all level3 (middle)
        document.querySelectorAll(".level3")
            .forEach(element => element.style.display = "none");

        // hide all level2 teasers (right)
        document.querySelectorAll(".level2__teaser__item")
            .forEach(element => element.style.display = "none");

        // show first level 3 (middle)
        document.querySelectorAll("[data-level-first='True']")
            .forEach(element => {element.style.display = "flex"; element.classList.add("menu-active")});

        // show selected level 2
        document.querySelectorAll("[data-level2-id='" + id + "']")
            .forEach(element => element.style.display = "flex");
        document.querySelector("#nav-overlay").style.display = "block";             
        this.hideOverlayOnScroll();
       
    },
    showLevel3AndTeaser: function(id) {
        // hide all level3 (middle)
        document.querySelectorAll(".level3")
            .forEach(element => element.style.display = "none");
        document.querySelectorAll(".level2-item")
            .forEach(element => element.classList.remove("menu-active"));

        // hide all level2 teasers (right)
        document.querySelectorAll(".level2__teaser__item")
            .forEach(element => element.style.display = "none");

        // show
        document.querySelectorAll("[data-level3-id='" + id + "']")
            .forEach(element => element.style.display = "flex");       
       
        document.querySelectorAll("[data-level2-teaser-id='" + id + "']")
            .forEach(element => element.style.display = "flex");   
        
    },
    hideOverlayOnScroll: function() {
        window.addEventListener("scroll", function () {
            if (this.window.scrollY > 160) {
                document.querySelector("#nav-overlay").style.display = "none";
            }
        });    
    }
}