import { Tabs } from '../src/Tabs';

export const Login = {
    _userNameInput: null,
    _passwordInput: null,
    _loginForm: null,
    _loginFailureMessage: null,
    _redirectUrl: null,
    init: function () {

        // Show the login and register account tabs.
        Tabs.showTabs([0, 1], 0);

        Login._userNameInput = document.getElementById('loginEmail');
        Login._passwordInput = document.getElementById('loginPassword');
        Login._loginFailureMessage = document.getElementById('loginFailure');
        Login._loginForm = document.getElementById('LoginForm');
        Login._redirectUrl = document.getElementById("loginRedirectUrl");

        const forgotPasswordLink = document.querySelector('.forgot-password-link');
        if (forgotPasswordLink) {
            forgotPasswordLink.addEventListener('click', Login.onForgotPasswordLinkClicked);
        }
        Login.addRevokeAccountLinkEventListener();

        const revokePasswordForm = document.querySelector('.revoke-password-form');
        if (revokePasswordForm) {
            const loginButton = document.querySelector('.login-menu-button');
            if (loginButton) {
                Tabs.showTabs([4], 4);
                loginButton.click();
            }
        }
    },
    addRevokeAccountLinkEventListener: function () {
        const revokeAccountLinks = document.querySelectorAll('.revoke-account-link');
        if (revokeAccountLinks) {
            revokeAccountLinks.forEach(function (link) {
                link.addEventListener('click', Login.onRevokeAccountLinkClicked);
            });
        }
    },
    onLoginError: function () {
        Login._passwordInput.value = '';
        Login._passwordInput.classList.add('invalid');
        Login._passwordInput.focus();
        Login._loginFailureMessage.classList.remove('visually-hidden');
    },
     onLoginSuccess: function () {
         location.href = Login._redirectUrl.value;
     },
    onRevokeAccountSuccess: function (data) {
        if (data === "") {
            // Empty response means successfully registered account.
            // Here we reload the current page.
            location.reload();
        }
    },
    onRegisteredAccountSuccess: function (data) {
        if (data === "") {
            // Empty response means successfully registered account.
            // Here we reload the current page.
            Tabs.showTabs([0, 1], 1);
            location.reload();
            return;
        }

        Login.addRevokeAccountLinkEventListener();

        
    },
    onForgotPasswordLinkClicked: function (e) {
        e.preventDefault();
        Tabs.showTabs([3], 3);
    },
    onRevokeAccountLinkClicked: function (e) {
        e.preventDefault();
        Tabs.showTabs([2], 2);
    },
    reset: function () {
        Login._passwordInput.classList.remove('invalid');
        Login._loginFailureMessage.classList.add('visually-hidden');

        document.querySelectorAll('.login-form, .revoke-account-form, .forgot-password-form, .revoke-password-form, .sms-validation-form, .register-account-form').forEach(function (form) {
            form.reset();
        });

        Tabs.showTabs([0, 1], 0);
    },
    hideModal: function () {
        let modal = document.querySelector('.login-signup-modal');
        const modalContainer = document.querySelector('.modal-container');

        if(modal){
            modal.classList.remove('popup-modal__is-visible');
            modalContainer.classList.remove('modal-container__is-visible');
        }
    }
};