export const Questionnaire = {
    backButton: null,
    titleElement: null,
    introElement: null,
    sendEmailElement: null,
    resultEmailElement: null,
    resultEmailButtonElement: null,
    emailSuccessElement: null,
    emailErrorElemet: null,
    counter: null,
    questionCounter: null,
    jsQuestionCounter: null,
    answers: null,
    showBeforeUnload: true,
    modalContainer: null,
    resultDict: new Object(),
    init: function () {
        if (!document.querySelector(".questionnairepage")) {
            return;
        }

        this.backButton = document.getElementById("js-calculator-backbutton");
        this.summaryElement = document.getElementById("js-summary");
        this.titleElement = document.getElementById("js-calculator_result_title");
        this.introElement = document.getElementById("js-calculator_result_intro");
        this.sendEmailElement = document.getElementById("js-calculator_result_send-email");
        this.resultEmailElement = document.getElementById('js-calculator_result_email');
        this.resultEmailButtonElement = document.getElementById('js-calculator_result_email_button');
        this.emailSuccessElement = document.getElementById('js-calculator_result_send-email-success');
        this.emailErrorElemet = document.getElementById('js-calculator_result_send-email-error');
        this.counter = document.getElementById("js-question-counter");
        this.modalContainer = document.querySelector(".questionnairepage__modal");
        this.openModalButton = document.getElementById("js-open-questionnairepage__modal");
        this.closeModalButton = document.getElementById("js-close-questionnairepage__modal");

        if (this.modalContainer != 'undefined' && this.modalContainer != null) {
            this.openModalButton.addEventListener('click', function (e) { Questionnaire.openModal(); });
            this.closeModalButton.addEventListener('click', function (e) { Questionnaire.closeModal(); });
        }
        this.questionCounter = 0;
        this.jsQuestionCounter = 0;
        this.answers = {};
        Questionnaire.hideResult();
        Questionnaire.showNextQuestion();

        window.addEventListener('beforeunload', function (e) {
            if (!Questionnaire.showBeforeUnload) {
                Questionnaire.showBeforeUnload = true;
                return null;
            }
            // Cancel the event
            e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
            // Chrome requires returnValue to be set
            e.returnValue = '';
        });

        // check if reset
        const urlParams = new URLSearchParams(window.location.search);
        const isReset = urlParams.get('reset') !== null;
        if (isReset) {
            if (document.getElementById("js-calculator"))
                Questionnaire.openModal();
        }
    },
    openModal: function () {
        this.modalContainer.classList.add("modal-open");
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
    },

    closeModal: function () {
        this.modalContainer.classList.remove("modal-open");
        document.getElementsByTagName("html")[0].style.overflow = "initial";
        document.getElementsByTagName("html")[0].style.overflowX = "hidden";
    },

    clickAnswer: function (type, value, e) {
        this.answers[type] = value;

        let question = e.parentElement.parentElement.parentElement.getElementsByClassName("question__heading")[0].textContent;
        let answer = e.getElementsByTagName("p")[0].textContent;

        this.resultDict[question] = answer;

        Questionnaire.showNextQuestion();
        document.querySelector("#questionnairepage")?.scrollIntoView();
    },

    showNextQuestion: function () {
        this.backButton.style.display = "none";
        if (this.questionCounter > 0) {
            this.backButton.style.display = "inline-block";
        }

        Questionnaire.hideQuestion();

        this.questionCounter++;

        if (Questionnaire.shouldSkipHouseConditionQuestion()) {
            this.questionCounter++;
        }

        this.jsQuestionCounter++;
        Questionnaire.showQuestion();

        if (Questionnaire.currentQuestion() === null) {
            Questionnaire.showResult();
        }
    },

    showPreviousQuestion: function () {
        this.backButton.style.display = "none";
        if (this.questionCounter > 2) {
            this.backButton.style.display = "inline-block";
        }

        Questionnaire.hideResult();
        Questionnaire.hideQuestion();
        this.questionCounter--;

        if (Questionnaire.shouldSkipHouseConditionQuestion()) {
            this.questionCounter--;
        }

        this.jsQuestionCounter--;
        Questionnaire.showQuestion();

    },

    shouldSkipHouseConditionQuestion: function () {
        // if house is NOT painted, skip house-condition question
        let question = Questionnaire.currentQuestion();
        if (question && question.dataset.type === "house-condition" && this.answers['is-house-painted'] !== 'true') {
            return true;
        }
        return false;
    },

    hideQuestion: function () {
        let question = Questionnaire.currentQuestion();
        if (question) {
            question.style.display = "none";
        }
    },

    showQuestion: function () {
        let question = Questionnaire.currentQuestion();
        if (question) {
            question.style.display = "block";
        }
    },

    currentQuestion: function () {
        return document.getElementById("question-" + this.questionCounter);
    },

    hideResult: function () {
        this.summaryElement.style.display = "none";
    },

    showResult: function () {
        this.summaryElement.style.display = "flex";

        let livingArea = 0;
        let toolsCost = 0;

        switch (this.answers["living-area"]) {
            case 'small':
                livingArea = 100;
                toolsCost = 2000;
                break;
            case 'medium':
                livingArea = 140;
                toolsCost = 3000;
                break;
            case 'large':
                livingArea = 200;
                toolsCost = 4000;
                break;
            case 'extra-large':
                livingArea = 250;
                toolsCost = 4000;
                break;
            case 'too-large':
                livingArea = null;
                toolsCost = null;
                break;
            default:
        }

        const totalArea = Questionnaire.getTotalArea(livingArea);

        const paintData = Questionnaire.getPaintTimeAndCost();
        const { paintTimePerSquareMeter, paintCostPerSquareMeter } = paintData;

        const baseTreatmentTimeAndCost = Questionnaire.getBaseTreatmentTimeAndCost();
        const { baseTreatmentTimePerSquareMeter, baseTreatmentCostPerSquareMeter } = baseTreatmentTimeAndCost;

        const { cleaningTimePerSquareMeter, cleaningCostPerSquareMeter } = Questionnaire.getCleaningTimeAndCost();

        const totalTimePerSquareMeter =
            paintTimePerSquareMeter + baseTreatmentTimePerSquareMeter + cleaningTimePerSquareMeter;
        const totalCostPerSquareMeter =
            paintCostPerSquareMeter + baseTreatmentCostPerSquareMeter + cleaningCostPerSquareMeter;

        const errorMargin = 0.025;
        const totalTime = totalArea * totalTimePerSquareMeter;
        const totalCost = totalArea * totalCostPerSquareMeter + toolsCost;

        const rangeSize = totalCost * errorMargin;
        const totalCostFrom = Math.floor((totalCost - rangeSize) / 1000) * 1000;
        const totalCostTo = Math.ceil((totalCost + rangeSize) / 1000) * 1000;

        const roundedTotalTime = Math.round(totalTime);
        const heading = `Det kommer ta ca ${roundedTotalTime} timmar att måla om ditt hus och kostnaden för färg och material blir ca ${totalCostFrom} - ${totalCostTo} kr.`;
        const intro = `Vi baserar kalkylen på en fasadyta = ${Math.round(
            totalArea
        )} m2 och underarbete motsvarande det skick du angett, samt att ni målar själva. Vill du ha ett mer specifikt prisunderlag - boka en fasadexpert!`;

        this.titleElement.textContent = heading;
        this.introElement.textContent = intro;
    },

    getTotalArea: function (livingArea) {
        let floorFactor = 0;
        let floorNumber = 0;
        switch (this.answers['floor-count']) {
            case 'small': floorFactor = 12;
                floorNumber = 1;
                break;
            case 'medium': floorFactor = 13.5;
                floorNumber = 1.5;
                break;
            case 'large': floorFactor = 18;
                floorNumber = 2;
                break;
        }

        let wallsNumberValue = 0;
        switch (this.answers['walls-number']) {
            case 'one':
                wallsNumberValue = 1;
                break;
            case 'two':
                wallsNumberValue = 2;
                break;
            case 'three':
                wallsNumberValue = 3;
                break;
            case 'four':
                wallsNumberValue = 4;
                break;
        }
        const wallLength = Math.sqrt(livingArea);

        const wallArea = wallLength * floorFactor * (wallsNumberValue / 4);
        const gableArea = 2 * wallLength;

        const windowArea = 1.5;
        const windowsPerFloor = 8;
        const totalWindowArea = floorNumber * windowArea * windowsPerFloor;

        const doorArea = 6;

        return wallArea + gableArea - totalWindowArea - doorArea;
    },

    getPaintTimeAndCost: function () {
        let costPerLiter = 0;
        let costPerSqm = 0;
        switch (this.answers['paint-to-be-used']) {
            case 'acrylate':
                costPerLiter = 280;
                costPerSqm = 7;
                break;
            case 'alkyd':
                costPerLiter = 245;
                costPerSqm = 7;
                break;
            case 'linseed-oil':
                costPerLiter = 250;
                costPerSqm = 7;
                break;
            case 'wood-stain':
                costPerLiter = 280;
                costPerSqm = 7;
                break;
            case 'calcimine':
                costPerLiter = 70;
                costPerSqm = 3;
                break;
        }
        const coatsToPaint = 2;
        const paintTimePerSquareMeter = 0.33 * coatsToPaint;

        const costPerSquareMeter = costPerLiter / costPerSqm;
        const paintCostPerSquareMeter = coatsToPaint * costPerSquareMeter;

        return {
            paintTimePerSquareMeter,
            paintCostPerSquareMeter
        };
    },

    getBaseTreatmentTimeAndCost: function () {
        const isHouseNotPainted = this.answers['is-house-painted'] === 'false';
        const isHouseInBadCondition = this.answers['house-condition'] === 'bad';

        const isBaseTreatmentNeeded = isHouseNotPainted || isHouseInBadCondition;

        if (!isBaseTreatmentNeeded) {
            return {
                baseTreatmentTimePerSquareMeter: 0,
                baseTreatmentCostPerSquareMeter: 0
            };
        }

        let paintTypeBase = '';
        switch (this.answers['paint-to-be-used']) {
            case 'acrylate':
                paintTypeBase = 'water';
                break;
            case 'alkyd':
            case 'linseed-oil':
            case 'wood-stain':
                paintTypeBase = 'Oil';
                break;
            case 'calcimine':
                paintTypeBase = 'No base';
                break;
        }
        const isSpecialPaint = paintTypeBase === 'No base';
        if (isSpecialPaint) {
            const paintData = Questionnaire.getPaintTimeAndCost();
            const { paintTimePerSquareMeter, paintCostPerSquareMeter } = paintData;

            // The special paint is applied once for base treatment, hence the division
            return {
                baseTreatmentTimePerSquareMeter: paintTimePerSquareMeter / 1,
                baseTreatmentCostPerSquareMeter: paintCostPerSquareMeter / 1
            };
        }

        const coatsToPaint = 1;
        let costPerLiter = 200; // oil
        let squareMetersPerLiter = 9 // oil;
        if (paintTypeBase === 'water') {
            costPerLiter = 200;
            squareMetersPerLiter = 6;
        }

        return {
            baseTreatmentTimePerSquareMeter: coatsToPaint * 0.33, // todo: move to prismic variable
            baseTreatmentCostPerSquareMeter: costPerLiter / squareMetersPerLiter
        };
    },

    getCleaningTimeAndCost: function () {
        const houseConditionAnswer = this.answers['house-condition'] !== undefined;
        if (!houseConditionAnswer) {
            // We presume the condition is pristine because if you haven't been asked about the condition,
            // you have answered that the house is not painted which we assume means completely new.
            return {
                cleaningTimePerSquareMeter: 0,
                cleaningCostPerSquareMeter: 0
            };
        }

        let cleaningTimePerSquareMeter = 0.2;
        let cleaningCostPerSquareMeter = 0.1;

        if (this.answers['house-condition'] === 'bad') {
            cleaningTimePerSquareMeter = 1;
            cleaningCostPerSquareMeter = 25;
        }
        return {
            cleaningTimePerSquareMeter,
            cleaningCostPerSquareMeter
        };
    },
    sendEmail: function () {
        this.emailSuccessElement.style.display = "none"
        this.emailErrorElemet.style.display = "none";

        let resultAnswers = '';
        for (const [key, value] of Object.entries(this.resultDict)) {
            resultAnswers = resultAnswers + '\n' + key + ": " + value;
        }

        let url = "/QuestionnairePage/sendemail";
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'email': document.getElementById('js-calculator_result_email').value,
                'resultAnswers': resultAnswers,
                'title': this.titleElement.textContent,
                'intro': this.introElement.textContent
            })
        })
            .then(response => {
                this.resultEmailElement.style.display = "none";
                this.resultEmailButtonElement.style.display = "none";

                if (!response.ok) {
                    this.emailErrorElemet.style.display = "block";
                }
                else {
                    this.emailSuccessElement.style.display = "block";
                }
            })
            .catch((error) => {
                this.emailErrorElemet.style.display = "block";
            });
    },
    goToPage: function (url) {
        Questionnaire.showBeforeUnload = false;
        window.location.href = url;
    }
}