export const Address = {
    init: function () {
        var specialHours = document.querySelectorAll('.specialhours');

        if (specialHours.length > 0) {
            specialHours.forEach(function (specialHour) {
                specialHour.querySelector('.expandlink').addEventListener('click', Address.toggle);                
            });
        }
    },
    toggle: function (e) {
        var expandLink = this;
        var specialHour = this.parentNode;
        specialHour.classList.toggle('specialHour-expanded');

        var expandedHeight = specialHour.querySelector('.expandable').offsetHeight;
        if (specialHour.classList.contains('specialHour-expanded')){
            specialHour.style.height = expandedHeight + "px";
            expandLink.innerHTML = expandLink.dataset.hidetext;
        }
        else {
            specialHour.style.height = "22px";
            expandLink.innerHTML = expandLink.dataset.extendtext;
        }
    }
}