export const Forms = {
    init: function () {
        if (!document.querySelector('.EPiServerForms')) {
            return;
        }

        var formElements = document.getElementsByClassName("FormTextbox__Input");

        Array.prototype.forEach.call(formElements, function (formElement) {
            var newEl = document.createElement("div");
            newEl.classList.add("form__label__description");
            newEl.innerText = formElement.title;
            Forms.insertAfter(formElement.previousSibling, newEl);
        });

        this.addStoreId();
    },
    insertAfter: function (referenceNode, newNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    },
    addStoreId: function () {
        let storeIdInput = document.getElementsByClassName('js-storeId')[0];

        let forms = document.querySelectorAll('.EPiServerForms');
        forms.forEach(form => {
            let input = form.querySelectorAll("input[value=StoreId]")[0];
            if (input && storeIdInput) {
                input.value = storeIdInput.value;
            }
        });
    }
};