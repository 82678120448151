export const LinkDropdown = {
    init: function () {
       
        const profileLinks = document.getElementsByClassName('profile__links');

        if (profileLinks.length > 0 && window.innerWidth < 576) {
            document.querySelector('.profile__links').classList.add('dropdown');
            document.querySelector('.profile__links').classList.add('closed');
        }

        const dropdowns = document.querySelectorAll('.dropdown');
        if (!document.querySelector('.dropdown')) return;

        if (dropdowns.length > 1) {
            dropdowns.forEach(function (dropdown) {
                dropdown.addEventListener('click', LinkDropdown.toggle);
            });
        } else {
            document.querySelector('.dropdown').addEventListener('click', LinkDropdown.toggle);
        }

        document.addEventListener('click', LinkDropdown.clickOutsideDropdown);
       
    },
    toggle: function (e) {        
        const currentDropdown = e.target.closest('.dropdown');

        currentDropdown.classList.toggle('closed');
    },
    clickOutsideDropdown: function (e) {
        const dropdowns = document.querySelectorAll('.dropdown');
        const clickedTarget = e.target.closest('.dropdown');

        if (clickedTarget === null) {                   
            if (dropdowns.length > 1) {
                dropdowns.forEach(function (dropdown) {
                    dropdown.classList.add('closed');
                });
            } else {
                document.querySelector('.dropdown').classList.add('closed');
            }
        }
    }
}
