export const PackageCalculator = {

    init: function () {
        if (!document.getElementById('packagecalculator-modal')) {
            return;
        }
        const uomValue = document.getElementById('uomcount').value.replace(',', '.');
        this.uomCount = parseFloat(uomValue);
    },

    uomCount: 0,

    calculate: function () {
        const size = parseFloat(document.getElementById('room-size').value);
        const packageResult = document.getElementById('package-result');
        if (size && !Number.isNaN(size) && size > 0) {
            let packages = size / PackageCalculator.uomCount;
            if (Number.isNaN(packages)) {
                packages = 0;
            }
            const packagesRoundedUp = Math.ceil(packages);
            const elId = packagesRoundedUp === 1 ? 'package-result-single' : 'package-result-multiple';
            const packagesText = document.getElementById(elId).value;
            const message = document.getElementById('package-result-message').value;
            const resultMessage = `${message} ${packagesRoundedUp} ${packagesText}`;
            packageResult.innerText = resultMessage;
            packageResult.classList.remove('d-none');
            document.getElementById('package-result-packagesRoundedUp').value = packagesRoundedUp;

        } else {
            packageResult.classList.add('d-none');
            document.getElementById('package-result-packagesRoundedUp').value = 1;
        }
    },
    addToCart: function () {
        let packagesRoundedUp = document.getElementById('package-result-packagesRoundedUp').value;
        let quantityInput = document.getElementById('id-quantity-input-product-details');

        // set page quantity input, and trigger page AddToCart
        if (quantityInput) {
            let previousQuantityInput = quantityInput.value; // input below modal
            quantityInput.value = packagesRoundedUp;

            let pageAddToCartButton = document.getElementsByClassName('js-add-to-cart')[0];
            if (pageAddToCartButton) {
                pageAddToCartButton.click();
            }

            // reset quantity input 
            quantityInput.value = previousQuantityInput;
        }
    }
};