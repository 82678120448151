import { SearchFlyOut } from '../src/SearchFlyOut';
import { MobileMenu } from '../src/MobileMenu';

export const WishlistFlyin = {    
    init: function () {        
        if (!document.querySelector('.js-toggle-wishlist')) return;

        document.addEventListener('click', function (e) { WishlistFlyin.closeWishList(e) });
    },
    toggleWishlist: function (e) {
        e.preventDefault();
        document.querySelector('.wishlist').classList.toggle('wishlist-expanded');
        SearchFlyOut.hideFlyout();
        MobileMenu.closeMobileNav();
    },
    closeWishList: function (e) {
        if (!document.querySelector('.wishlist').classList.contains('wishlist-expanded')) return;

        var isClickInsideWishlist = document.querySelector('.wishlist-expanded').contains(e.target);
        var isOpenButton = document.querySelector('.js-toggle-wishlist').contains(e.target);

        if (!isClickInsideWishlist && !isOpenButton) {
            document.querySelector('.wishlist').classList.remove('wishlist-expanded');
        }
    }
};