export const Search = {
    query: null,
    trackId: null,

    init: function () {
        const searchQueryElement = document.getElementById("jsSearchQuery");
        const trackIdElement = document.getElementById("jsTrackId");
        if (searchQueryElement && trackIdElement) {
            Search.query = searchQueryElement.value;
            Search.trackId = trackIdElement.value;
        }
    },
    track: function (hitId, trackId, query) {

        if (!trackId) {
            trackId = Search.trackId;
        }
        if (!query) {
            query = Search.query;
        }

        navigator.sendBeacon("/ClickTracking/Track?query=" + query + "&hitid=" + hitId + "&trackid=" + trackId);
    }
}