import { Cart } from '../src/Cart';

export const CartPage = {

    init: function () {
        if (!document.querySelector('.js-cart-page')) {
            return;
        }

        const items = document.querySelectorAll('.js-lineitem');

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.querySelector('.js-quantity-input')
                .addEventListener('change', function (e) {
                    CartPage.changeQuantity(item, e, false);
                });

            const btnItems = item.querySelectorAll('.js-quantity-button');
            for (let i = 0; i < btnItems.length; i++) {
                btnItems[i].addEventListener('click', function (e) {
                    CartPage.changeQuantity(item, e, true);
                });
            }
        }

        Cart.updateCart();
    },
    changeActiveDeliveryMethod: function (item) {
        if (item.classList.contains("disabled") || item.classList.contains("selected")) {
            return;
        }
        const deliveryMethods = document.querySelectorAll(".delivery-method-container");
        for (var i = 0; i < deliveryMethods.length; i++) {
            const deliveryMethod = deliveryMethods[i];
            deliveryMethod.classList.remove("selected");
        }
        item.querySelector('.delivery-method__radio').click();
        item.classList.add('selected');

        CartPage.inactivateDropdown();
    },
    inactivateDropdown: function () {
        const pickup = document.querySelector('#pickup-dropdown');
        if (pickup !== null && pickup !== 'undefined') {
            pickup.disabled = !pickup.parentNode.parentNode.classList.contains('selected');
        }
    },
    changeQuantity: function (item, e, updateQuantity) {
        const loaderElement = document.querySelector('.loader');
        const totalsElement = document.querySelector('.cartpage__totals');
        if (loaderElement && totalsElement) {
            loaderElement.style.display = "block";
            totalsElement.style.display = "none";
        }
        const input = item.querySelector('.js-quantity-input');

        function calcNewQty() {
            const quantity = parseInt(e.currentTarget.value);
            if (quantity === 0) return 0;
            return updateQuantity ? parseInt(input.value) + quantity : quantity;
        }

        const newQuantity = calcNewQty();

        if (newQuantity < 0) return;

        if (updateQuantity) {
            input.value = newQuantity;
        }


        const sku = input.dataset.productSku;
        const colorCode = input.dataset.productColorcode;

        Cart.UpdateLineItemQuantityFromSku(sku, colorCode, newQuantity)
            .then(
                function (cart) {
                    Cart.updateShippingCost(cart.ShippingCostFormatted);
                    Cart.updateTotalCost(cart.TotalCostFormatted);
                    Cart.updateVat(cart.VATFormatted);
                    Cart.updateActiveCouponValue(cart.ActivePromotionValueFormatted);

                    if (loaderElement && totalsElement) {
                        loaderElement.style.display = "none";
                        totalsElement.style.display = "block";
                    }
                    Cart.updateCart();
                    CartPage.refreshDeliveryMethods();

                    let lineItemNo = item.dataset.lineItemNo;
                    item.querySelector('.js-product-total').innerText = cart.LineItems[lineItemNo].PriceTotalFormatted + " kr";
                }
            );

        if (newQuantity === 0) {
            item.parentNode.removeChild(item);

            const productId = input.dataset.productId;
            const productName = input.dataset.productName;
            const productPrice = input.dataset.productPrice;
            const productCode = input.dataset.productCode;
            const productBrand = input.dataset.productBrand;
            const productCategory = input.dataset.productCategory;
            const productVariant = input.dataset.productVariant;

            GTMEvents.removeFromCartGTM(productCode, productName, productPrice, productBrand, productCategory, productVariant, newQuantity);
        }
    },
    refreshPaymentForm: function () {
        let paymentContainer = document.querySelector('.cartpage__payment');
        if (!paymentContainer) {
            return;
        }

        let storeId = '';
        const pickupLocations = document.getElementById('pickup-dropdown');
        if (pickupLocations && !pickupLocations.disabled) {
            if (pickupLocations.value !== '') {
                storeId = '?storeId=' + pickupLocations.value;
            }
        }

        const url = paymentContainer.dataset.url + storeId;

        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.json())
            .then(response => {
                paymentContainer.innerHTML = response.Script;
                collector.checkout.api.init();
                paymentContainer.style.display = 'block';
                CartPage.updateCurrentOrderGroupId(response.OrderGroupId);
            })
            .catch((error) => {                
            });

    },
    showPaymentForm: function () {
        const paymentContainer = document.querySelector('.cartpage__payment');
        const deliveryMethod = document.querySelector("input[name=deliverymethod]:checked");
        if (!deliveryMethod) {
            // Selected delivery option is required before showing
            // the payment container.
            paymentContainer.style.display = 'none';
            return;
        }
        paymentContainer.style.display = 'block';
    },
    updateCurrentOrderGroupId: function (orderGroupId) {
        var orderGroupIdElement = document.getElementById('OrderGroupId');
        orderGroupIdElement.value = orderGroupId;
    },
    refreshDeliveryMethods: function () {
        let orderGroupId = document.getElementById('OrderGroupId').value;
        let deliveryMethodPickupId = document.querySelector('input[name="deliverymethod"]:checked').value;
        let pickupId = '';

        let pickupDropdown = document.getElementById("pickup-dropdown");
        if (pickupDropdown) {
            pickupId = pickupDropdown.value;
        }

        var url = 'GetDeliveryMethods'
            + '?orderGroupId=' + orderGroupId
            + '&selectedDeliveryMethod=' + deliveryMethodPickupId
            + '&selectedPickup=' + pickupId;

        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
        })
            .then(response => response.text())
            .then(html => {
                let deliveryMethodElement = document.getElementById('js-delivery-methods');
                deliveryMethodElement.innerHTML = html;

            })
            .catch((error) => {                
            });
    },
};