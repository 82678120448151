import { Cookies } from '../src/Cookies';
import { GTMEvents } from '../src/GTMEvents';
import { MinicartFlyin } from '../src/MinicartFlyin';

document.addEventListener('DOMContentLoaded', function () {
    Minicart.updateMiniCart();
});

export const Minicart = {
    init: function () {
        if (document.querySelector('.js-cart-page')) {
            return;
        }
        
    },
    updateMiniCart: function () {
        if (!Cookies.getCookie('OrderGroupId')) {
            return;
        }
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                let deliveryTranslation = document.getElementById('minicart-shipping-translation').innerText;

                var minicart = JSON.parse(this.responseText);
                var minicartAmountOfItems = 0;
                let productList = document.getElementsByClassName('minicart-productlist')[0];
                productList.innerHTML = '';
                var productDetailsUrl = minicart.ProductDetailsUrl;

                minicart.LineItems.forEach(element => {
                    // minicart product
                    let productLink = document.createElement('a');
                    let productLink2 = document.createElement('a');
                    if (element.Properties["colorama-parent-skucode"] != "") {
                        productLink.href = productDetailsUrl + element.CanCanURLValue;
                        productLink2.href = productDetailsUrl + element.CanCanURLValue;
                    }
                    else if (element.CanCanURLValue) {
                        productLink.href = productDetailsUrl + element.CanCanURLValue + "_" + element.SkuCode;
                        productLink2.href = productDetailsUrl + element.CanCanURLValue + "_" + element.SkuCode;
                    } else {
                        productLink.href = productDetailsUrl + element.ProductId;
                        productLink2.href = productDetailsUrl + element.ProductId;
                    }
                    let product = document.createElement('div');
                    product.className = 'minicart-product cart__productitem';

                    //product image
                    let productImageDiv = document.createElement('div');
                    productImageDiv.className = 'cart__productimage';
                    var img = document.createElement('img');
                    img.src = 'https://colorama.cdn.storm.io/' + element.ImageKey + '?width=140&height=140&mode=crop&quality=80';
                    productImageDiv.appendChild(img);
                    productLink.appendChild(productImageDiv)
                    product.appendChild(productLink);

                    //product text container
                    let productContainer = document.createElement('div');
                    productContainer.className = 'cart__productitem__container';

                    product.appendChild(productContainer);

                    //product name
                    let productNameDiv = document.createElement('h3');
                    productNameDiv.textContent = element.Name;
                    productNameDiv.className = 'cart__productname';
                    productLink2.appendChild(productNameDiv);

                    // partNo
                    let partNoDiv = document.createElement('div');
                    partNoDiv.textContent = 'PartNo: ' + element.SkuCode;
                    partNoDiv.className = 'cart__partno';
                    productLink2.appendChild(partNoDiv);

                    // color info
                    if (element.Properties["colorama-infotypes-color"]) {
                        let colorInfoDiv = document.createElement('div');
                        colorInfoDiv.textContent = element.Properties["colorama-infotypes-color"];
                        colorInfoDiv.className = 'cart__colorinfo';
                        productLink2.appendChild(colorInfoDiv);
                    }

                    // price
                    let priceDiv = document.createElement('div');
                    let priceSpan = document.createElement('span');

                    priceSpan.textContent = element.PriceFormatted + " kr";
                    priceDiv.appendChild(priceSpan);
                    priceDiv.className = 'cart__itemprice';
                    productLink2.appendChild(priceDiv);

                    // shipping 
                    let shippingDiv = document.createElement('div');
                    shippingDiv.textContent = deliveryTranslation + " " + element.ShippingTime;
                    shippingDiv.className = 'cart__shipping';
                    productLink2.appendChild(shippingDiv);
                    productContainer.appendChild(productLink2);

                    // quantity
                    let quantityInput = document.createElement('input');
                    quantityInput.type = 'number';
                    quantityInput.className = 'quantity-input';
                    quantityInput.value = element.Quantity;
                    quantityInput.addEventListener('change', function (e) { Minicart.updateLineItem(element.SkuCode, element.Price, quantityInput, priceDiv, null, element.Name, element.SkuCode, element.Brand, element.Category, element.Variant); });

                    // quantity decrement
                    let decrementButton = document.createElement('button');
                    decrementButton.className = 'quantity-button quantity-button--remove';
                    decrementButton.addEventListener('click', function (e) { Minicart.updateLineItem(element.SkuCode, element.Price, quantityInput, priceDiv, -1, element.Name, element.SkuCode, element.Brand, element.Category, element.Variant); });

                    let decrementIcon = document.createElement('span');
                    decrementIcon.className = 'icon';

                    decrementButton.appendChild(decrementIcon);

                    // quantity increment
                    let incrementButton = document.createElement('button');
                    incrementButton.className = 'quantity-button quantity-button--add';
                    incrementButton.addEventListener('click', function (e) { Minicart.updateLineItem(element.SkuCode, element.Price, quantityInput, priceDiv, 1, element.Name, element.SkuCode, element.Brand, element.Category, element.Variant); });

                    let incrementIcon = document.createElement('span');
                    incrementIcon.className = 'icon';

                    incrementButton.appendChild(incrementIcon);

                    // remove button
                    let removeButton = document.createElement('button');
                    removeButton.className = 'cart__removeproduct';
                    removeButton.dataset.skucode = element.SkuCode;
                    removeButton.dataset.quantity = element.Quantity;
                    removeButton.addEventListener('click', function (e) { Minicart.updateLineItem(element.SkuCode, element.Price, quantityInput, priceDiv, 0, element.Name, element.SkuCode, element.Brand, element.Category, element.Variant); });

                    // add quantity buttons
                    let quantityContainer = document.createElement('div');
                    quantityContainer.className = 'cart__quantity-btns';

                    quantityContainer.appendChild(decrementButton);
                    quantityContainer.appendChild(quantityInput);
                    quantityContainer.appendChild(incrementButton);

                    productContainer.appendChild(quantityContainer);
                    productContainer.appendChild(removeButton);

                    productList.appendChild(product);
                    minicartAmountOfItems += Number(element.Quantity);
                });

                let totalPriceElement = document.getElementById("minicart-orderdetails-price-id");
                totalPriceElement.textContent = minicart.TotalItemCostFormatted + " kr";

                let vatElement = document.getElementById("minicart-orderdetails-vat-id");
                vatElement.textContent = minicart.TotalItemVATCostFormatted + " kr";

                // update minicart icon count
                let minicartIcon = document.querySelector('.js-minicart-count');
                if (minicartIcon) {
                    if (minicartAmountOfItems > 0) {
                        minicartIcon.classList.add("js-minicart-count-active");
                        minicartIcon.innerHTML = minicartAmountOfItems;
                    }
                    else if (minicartAmountOfItems === 0) {
                        minicartIcon.innerHTML = '';
                        minicartIcon.classList.remove("js-minicart-count-active");
                    }
                }
            }
        };
        xmlhttp.open('GET', '/Cart/GetMiniCart', true);
        xmlhttp.send();
    },
    updateLineItem: function (sku, price, quantityButton, priceDiv, quantityChange, name, skuCode, brand, category, variant) {
        let newQuantity = Number(quantityButton.value);

        if (quantityChange === 0) {
            newQuantity = 0;
            quantityButton.parentNode.style.display = "none";
            GTMEvents.removeFromCartGTM(skuCode, name, '' + price + '', brand, category, variant, newQuantity);
        }
        else if (quantityChange !== null) {
            newQuantity = newQuantity + quantityChange;
        }
        quantityButton.value = newQuantity;

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                Minicart.updateMiniCart();
            }
        };
        xmlhttp.open('POST', '/Cart/UpdateLineItemQuantity?sku=' + sku + '&quantity=' + newQuantity);
        xmlhttp.send();

    }
};