import { SearchFlyOut } from '../src/SearchFlyOut';
import { MobileMenu } from '../src/MobileMenu';

export const MinicartFlyin = {    
    init: function () {        
        document.addEventListener('click', function (e) { MinicartFlyin.closeMinicart(e); });
    },
    toggleMinicart: function (e) {               
        e.preventDefault();
        document.querySelector('.minicart').classList.toggle('minicart-expanded');
        document.querySelector('.minicart-bottom').classList.toggle('minicart-expanded');
        SearchFlyOut.hideFlyout();
        MobileMenu.closeMobileNav();
    },
    closeMinicart: function (e) {              
        if (!document.querySelector('.minicart').classList.contains('minicart-expanded')) return;

        var isClickInsideMinicart = document.querySelector('.minicart-expanded').contains(e.target);
        var isOpenButton = document.querySelector('.js-toggle-minicart').contains(e.target);

        if (!isClickInsideMinicart && !isOpenButton) {
            document.querySelector('.minicart').classList.remove('minicart-expanded');
            document.querySelector('.minicart-bottom').classList.remove('minicart-expanded');
        }
    }
};