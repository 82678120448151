import { CustomColorFlyin } from '../src/CustomColorFlyin';
import { Cart } from '../src/Cart';
import { ModalPopup } from '../src/Modal';
import { Accordions } from '../src/Accordions';
import { GTMEvents } from '../src/GTMEvents';
import { tns } from 'tiny-slider';
import Swiper from 'swiper';
import 'swiper/css';

export const Product = {
    init: function (colorSelected = false) {
        if (!document.querySelector('.productdetailspage')) {
            return;
        }

        // Hide variants if only ONE and not 'valfri', adding class hide that only has effect on mobile
        var variantHolder = document.querySelector('.product-properties__variant-collection');
        if (variantHolder) {
            var variants = variantHolder.querySelector('.product-properties__thumbnails');
            if (variants) {
                var variantLinks = variants.querySelectorAll('a');
                if (variantLinks && variantLinks.length === 1 && !variantLinks[0].classList.contains('js-toggle-customcolorflyin')) {
                    variantHolder.classList.add('hide');

                    var amountTitleElement = document.getElementById('product-properties-label');
                    if (amountTitleElement) {
                        amountTitleElement.textContent = amountTitleElement.textContent.replace("2. ", "");
                    }
                }
            }
        }

        const variantThumbnails = document.querySelectorAll('.variant-thumbnail');
        if (variantThumbnails) {
            variantThumbnails.forEach(function (thumbnail) {
                thumbnail.addEventListener('click', function (e) {
                    e.preventDefault();
                    const variantId = this.dataset.variantId;
                    if (this.dataset.colorName !== 'valfri') {
                        document.getElementById('colorinfo').value = '';
                        Product.changeSelectedVariant(variantId, null, true);
                    }
                    else {
                        Product.changeSelectedVariant(variantId, null, false);
                    }
                });
            });
        }

        const packageSizeDropdown = document.getElementById('product-package-size');
        if (packageSizeDropdown) {
            packageSizeDropdown.addEventListener('change', function () {
                const variantThumbnailSelected = document.querySelector('.thumbnail.selected');
                const variantId = this.options[this.selectedIndex].value;
                if (variantThumbnailSelected) {
                    var notValfriThumb = variantThumbnailSelected.id != "jsSelectedThumnail" ? true : false;
                    Product.changeSelectedVariant(variantId, null, notValfriThumb, true);
                }
                else
                    Product.changeSelectedVariant(variantId);
            });
        }

        const quantityInput = document.querySelector('.quantity-input');
        if (quantityInput) {
            const addButton = document.querySelector('.quantity-button--add');
            const removeButton = document.querySelector('.quantity-button--remove');

            if (addButton) {
                addButton.addEventListener('click', function () { Product.changeQuantity(quantityInput, 1) })
            }

            if (removeButton) {
                removeButton.addEventListener('click', function () { Product.changeQuantity(quantityInput, -1) })
            }
        }

        let numOfImages = document.querySelectorAll('.product-images__carousel .item').length;
        const thumbnailsContainer = document.getElementById('productimage-thumbnails');

        Product.productsDetailsUrl = document.getElementById('ProductsDetailsUrl').value;

        //if color is picked increase num to make slideshow and show image of picked color
        if (colorSelected == true) { numOfImages++; }

        if (numOfImages > 1) {            
            if (window.innerWidth < 576) {
                let mobileSwiper = document.getElementById("jsMobileSwiper");
                if (mobileSwiper === false) {
                    return;
                }
                mobileSwiper.classList.add("swiper");
                mobileSwiper.classList.add("mySwiper");

                let wrapper = document.querySelector(".product-images__carousel");
                if (wrapper === false) {
                    return;
                }
                wrapper.classList.add("swiper-wrapper");
                let sliderItems = wrapper.querySelectorAll("div.item");
                sliderItems.forEach(function (item) {
                    item.classList.add("swiper-slide");
                });                
                var swiper = new Swiper(".mySwiper", {
                    slidesPerView: "auto",
                    spaceBetween: 20,
                    pagination: {
                        el: ".swiper-pagination",
                        type: "fraction",
                    },
                });
            }
            else { // desktop
                thumbnailsContainer.style.display = 'flex';

                let slider = new tns({
                    mode: 'gallery',
                    animateIn: 'fadeInDown',
                    animateOut: 'fadeOutDown',
                    animateDelay: 300,
                    container: '.product-images__carousel',
                    navContainer: thumbnailsContainer,
                    navAsThumbnails: true,
                    items: 1,
                    slideBy: 'page',
                    autoplay: false,
                    controls: false,
                    autoHeight: true
                });
            }
        }
    },
    productsDetailsUrl: null,
    changeQuantity: function (input, value) {
        const updatedValue = parseInt(input.value) + value;

        if (updatedValue >= 0) {
            input.value = updatedValue;
        }
    },
    changeSelectedVariant: function (variantId, goBack, ColorPicked = false, isDropDownUsed = false) {
        const colorInfoElement = document.getElementById('colorinfo');
        if (!colorInfoElement) {
            return;
        }
        const colorinfo = colorInfoElement.value;
        var url = `Product.productsDetailsUrl?variantId=${variantId}&customColor=${colorinfo}&customColorCode=${CustomColorFlyin.selectedThumbnailColorTemp}&isDropDownUsed=${isDropDownUsed}`;

        fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'text/html',
                'Accept': 'text/html',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.text())
            .then(html => {
                const productsInSameCategoryListHtml = document.querySelector('.products-in-same-category').innerHTML;
                const container = document.querySelector('.productdetailspage');
                container.innerHTML = html;

                // getting product wont return productlist to save some seconds.
                const newProductsInSameCategoryList = container.querySelector('.products-in-same-category');
                newProductsInSameCategoryList.innerHTML = productsInSameCategoryListHtml;

                //if color is picked and is type color set slideshow to that color
                var prodType = document.getElementById("ProductType") && document.getElementById("ProductType").value != null ? document.getElementById("ProductType").value : "";
                if (ColorPicked == true && prodType == "Color")
                    Product.createSlideColor();
                else
                    ColorPicked = false;

                Product.init(ColorPicked);
                CustomColorFlyin.init();
                Cart.init();
                ModalPopup.init();
                Accordions.init();

                if (!goBack) {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(html, 'text/html');
                    var variantUrl = doc.getElementById('ProductsDetailsUrl').value;
                    var currentStore = document.querySelector('.js-storename').value;
                    if (currentStore !== '') {
                        currentStore = '?butik=' + currentStore
                    }
                    var newurl = window.location.protocol + "//" + window.location.host + variantUrl + currentStore;

                    window.history.pushState({ path: newurl }, '', newurl);
                }

                let hiddenVariantTrackingElement = document.getElementById('js-variant-track');
                GTMEvents.handleLinkClick(null, hiddenVariantTrackingElement);
                GTMEvents.listedProducts = 0;
                GTMEvents.pushProductImpressionAndOrDetails();
            })
            .catch((error) => {                
            });
    },
    createSlideColor: function () {
        const variantThumbnail = document.querySelector('.thumbnail.selected');
        var color = variantThumbnail.style.backgroundColor;
        var thumbDiv = document.createElement('div');
        thumbDiv.className = "thumbnail-color";

        const markup = `
                    <div class="thumbnail" style="background-color:`+ color + `"> </div>
        `;
        thumbDiv.innerHTML = markup;
        document.getElementById('productimage-thumbnails').prepend(thumbDiv);

        var sliderDiv = document.createElement('div');
        sliderDiv.className = "item";
        const sliderMarkup = `
                <div style="background-color:`+ color + `; aspect-ratio:1; width:100%; margin-bottom: 9px;  border: 1px solid gray;">
                </div>
            `;
        sliderDiv.innerHTML = sliderMarkup;
        document.querySelector('.product-images__carousel').prepend(sliderDiv);
    },

};