export const WallpaperCalculator = {
    calculate: function () {
        let roomWidth = parseFloat(document.getElementById('room-width').value);
        let roomHeight = parseFloat(document.getElementById('room-height').value);

        if (roomWidth && roomHeight && !Number.isNaN(roomWidth) && !Number.isNaN(roomHeight)) {
            roomWidth = parseInt(roomWidth * 100, 0);
            roomHeight = parseInt(roomHeight * 100, 0);

            const rollLength = parseInt(document.getElementById('roll-length').value, 0);
            const rollWidth = parseInt(document.getElementById('roll-width').value, 0);
            const rollRepetition = parseInt(document.getElementById('roll-repetition').value, 0);
            const singleResult = document.getElementById('roll-result-single').value;
            const multipleResult = document.getElementById('roll-result-multiple').value;
            const message = document.getElementById('roll-result-message').value;
            const errorMessage = document.getElementById('js-wallpaper-calculator-error-message').value;
            let resultMessage;
            const stripsRaw = rollLength / (roomHeight + rollRepetition);
            const strips = stripsRaw < 0 ? Math.ceil(stripsRaw) : Math.floor(stripsRaw);

            const stripWidth = strips * rollWidth;

            let numRolls = Math.round((roomWidth / stripWidth) * 10000) / 10000;

            if (Number.isNaN(numRolls) || !isFinite(numRolls)) {
                numRolls = 0;
            }

            const numRollsRoundedUp = Math.ceil(numRolls);

            if (roomHeight > 900) {
                resultMessage = errorMessage;
            }
            else if (numRollsRoundedUp === 1) {
                resultMessage = `${message} ${numRollsRoundedUp} ${singleResult}`;
            }
            else {
                resultMessage = `${message} ${numRollsRoundedUp} ${multipleResult}`;
            }

            document.getElementById('roll-result').innerHTML = resultMessage;
            document.getElementById('roll-result').classList.remove('d-none');
            document.getElementById('roll-result-numRollsRoundedUp').value = numRollsRoundedUp;
        } else {
            document.getElementById('roll-result').classList.add('d-none');
            document.getElementById('roll-result-numRollsRoundedUp').value = 1;
        }
    },
    addToCart: function () {
        let numRollsRoundedUp = document.getElementById('roll-result-numRollsRoundedUp').value;
        let quantityInput = document.getElementById('id-quantity-input-product-details');

        // set page quantity input, and trigger page AddToCart
        if (quantityInput) {
            let previousQuantityInput = quantityInput.value; // input below modal
            quantityInput.value = numRollsRoundedUp;

            let pageAddToCartButton = document.getElementsByClassName('js-add-to-cart')[0];
            if (pageAddToCartButton) {
                pageAddToCartButton.click();
            }

            // reset quantity input 
            quantityInput.value = previousQuantityInput;
        }
    }
};